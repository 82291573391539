import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import SpinnerOverlay from '../Other/Loader';

const ExpenseTable = () => {
	const [expenses, setExpenses] = useState([]);
	const [loading, setLoading] = useState(true);

	const groupId = localStorage.getItem("contGroupId");
	const userId = localStorage.getItem('contrUserId');

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [totalPages, setTotalPages] = useState(1);
	const [totalEntries, setTotalEntries] = useState(0);

	// console.log("Group id:: ", groupId);

	function getLabel(value) {
		const valueLabelPairs = [
			['income', 'Income'],
			['expense', 'Expense'],
			['transfer', 'Transfer'],
		];

		for (const pair of valueLabelPairs) {
			const [val, label] = pair;
			if (val === value) {
				return label;
			}
		}

		return 'Unknown'; // Default label if the value is not found
	}

	const [searchTerm, setSearchTerm] = useState('');
	const handleSearch = (e) => {
		setSearchTerm(e.target.value);
	};

	const options = [
		{ value: 'income', label: 'Income' },
		{ value: 'expense', label: 'Expense' },
		{ value: 'transfer', label: 'Transfer' },
	];
	const [selectedOption, setSelectedOption] = useState('');
	const activationOptions = [
		{ value: 'True', label: 'Activated' },
		{ value: 'False', label: 'Deactivated' },
	];
	const [selectedActivationOption, setSelectedActivationOption] = useState('');

	const filteredExpenses = expenses.filter((expense) => {
		if (searchTerm.trim() === '') {
			return true;
		}

		const lowerCaseSearchTerm = searchTerm.toLowerCase();
		const lowerCaseActivatedId = expense.id.toString().toLowerCase();
		const lowerCaseTitle = expense.title.toLowerCase();
		const lowerCaseDate = expense.date.toLowerCase();
		const lowerCaseActivated = expense.is_activated ? "Activated" : "Deactivated";
		const lowerCaseCategory = expense.category.toLowerCase();

		return (
			lowerCaseActivatedId.includes(lowerCaseSearchTerm) ||
			lowerCaseTitle.includes(lowerCaseSearchTerm) ||
			lowerCaseDate.includes(lowerCaseSearchTerm) ||
			lowerCaseActivated.toLowerCase().includes(lowerCaseSearchTerm) ||
			lowerCaseCategory.includes(lowerCaseSearchTerm)
		);
	});

	const handleSelectChange = (e) => {
		setSelectedOption(e.target.value);
	};

	const handleSelectActivationOptionChange = (e) => {
		setSelectedActivationOption(e.target.value);
	};

	const nbOptions = [
		{ label: "1", value: 1 },
		{ label: "5", value: 5 },
		{ label: "10", value: 10 },
		{ label: "20", value: 20 },
		{ label: "50", value: 50 },
	]

	const handleSelectPageNumberChange = (e) => {
		setItemsPerPage(parseInt(e.target.value));
	};

	useEffect(() => {
		const fetchExpenses = async () => {
			const cacheBuster = new Date().getTime();
			try {
				let params = {};
				if (selectedOption && selectedActivationOption) {
					// If both selected, fetch with both parameters
					params = {
						category: selectedOption,
						is_activated: selectedActivationOption,
					};
				} else if (selectedOption) {
					// If only category selected, fetch with category parameter
					params = { category: selectedOption };
				} else if (selectedActivationOption) {
					// If only activated selected, fetch with is_activated parameter
					params = { is_activated: selectedActivationOption };
				} else if (!selectedActivationOption || !selectedOption) {
					// Fetch all expenses for the group ID
					const allExpensesResponse = await axios.get(`https://django.burundientempsreel.com/btr/cotisations/expense/${groupId}/?page_size=${itemsPerPage}&page=${currentPage}&cacheBuster=${cacheBuster}`);

					setTotalEntries(Math.ceil(allExpensesResponse.data.count));
					if (allExpensesResponse.data.results.length > 0) {
						setExpenses(allExpensesResponse.data.results);
						setTotalPages(Math.ceil(allExpensesResponse.data.count / itemsPerPage));
					}
					return;
				}

				// Fetch expenses based on selected options
				const filteredExpensesResponse = await axios.get('https://django.burundientempsreel.com/api/expenses/filter/', { params });
				setExpenses(filteredExpensesResponse.data);

			} catch (error) {
				console.error('Error fetching expenses:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchExpenses();
	}, [selectedOption, selectedActivationOption, groupId, currentPage, itemsPerPage]);

	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
	};
	const goToPreviousPage = (page) => {
		if (page > 1) {
			setCurrentPage(page - 1);
		}
	};
	const goToNextPage = (page) => {
		if (page < totalPages) {
			setCurrentPage(page + 1);
		}
	};

	const handleDelete = async (id) => {
		try {
			await axios.delete(`/api/expenses/${id}/`);
			// Remove the deleted expense from the list
			setExpenses(expenses.filter(expense => expense.id !== id));
			alert('Expense deleted successfully!');
		} catch (error) {
			console.error('Error deleting expense:', error);
			alert('An error occurred while deleting the expense. Please try again.');
		}
	};


	return (
		<div className='max-w-screen-xl mx-auto p-4 m-3'>
			<SpinnerOverlay isLoading={loading} />
			{!loading &&
				<>
					<div className='flex justify-center items-center mb-3'>
						<h2 className='font-bold text-2xl'>All Expenses</h2>
					</div>
					<div className="overflow-x-auto w-full p-2 rounded-md shadow-md bg-slate-100">
						<div className="w-full flex justify-between items-center flex-wrap">
							<div className="flex justify-between items-center flex-wrap mb-4">
								<div className='flex flex-col justify-center items-start m-1 p-1'>
									<label htmlFor="searchText" className='text-lg text-gray-800'>Filter contributions</label>
									<input type="text" id='searchText'
										placeholder="Filter expenses..."
										value={searchTerm}
										onChange={handleSearch}
										className="border border-gray-400 px-2 py-1 w-full rounded-md"
									/>
								</div>
								<div className='flex flex-col justify-center items-start m-1 p-1'>
									<label htmlFor="selectOption" className='text-lg text-gray-800'>Select category</label>
									<select id="selectOption" value={selectedOption} className='border border-gray-400 rounded-md px-2 py-1'
										onChange={handleSelectChange}>
										<option value="">Select...</option>
										{options.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
									{/* {selectedOption && <p>Selected option: {selectedOption}</p>} */}
								</div>
								<div className='flex flex-col justify-center items-start m-1 p-1'>
									<label htmlFor="selectedActivationOption" className='text-lg text-gray-800'>Select actif</label>
									<select id="selectedActivationOption" value={selectedActivationOption} className='border border-gray-400 rounded-md px-2 py-1'
										onChange={handleSelectActivationOptionChange}>
										<option value="">Select...</option>
										{activationOptions.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
									{/* {selectedActivationOption && <p>Selected option: {selectedActivationOption}</p>} */}
								</div>
								<div className='flex flex-col justify-center items-start m-1 p-1'>
									<label htmlFor="selectedPageNumb" className='text-sm text-gray-800'>Number per page</label>
									<select id="selectedPageNumb" value={itemsPerPage} className='w-full border border-gray-400 rounded-md px-2 py-1'
										onChange={handleSelectPageNumberChange}>
										<option value="">Number...</option>
										{nbOptions.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>
							<Link to="/expense/create/" className="text-white bg-blue-600 mx-2 my-2 p-2 px-4 rounded hover:bg-blue-800">Add an expense</Link>
						</div>

						<table className="table-auto w-full">
							<thead>
								<tr>
									<th className="border-2 px-4 py-2">Title</th>
									<th className="border-2 px-4 py-2">Amount</th>
									<th className="border-2 px-4 py-2">Date</th>
									<th className='border-2 px-4 py-2'>Activated</th>
									<th className="border-2 px-4 py-2">Category</th>
									<th className="border-2 px-4 py-2">Actions</th>
								</tr>
								{filteredExpenses.length === 0 && (
									<tr>
										<th
											className='border-2 px-4 py-2 col-span-5 bg-red-800 text-white'
											colSpan='6'
										>
											<span className="mx-2">No expenses made for now</span>
										</th>
									</tr>
								)}
							</thead>
							<tbody>
								{filteredExpenses.map(expense => (
									<tr key={expense.id}>
										<td className="border-2 px-4 py-2">{expense.title.substring(0, 25)}{expense.title.length > 0 && "..."}</td>
										<td className="border-2 px-4 py-2">{expense.amount}</td>
										<td className="border-2 px-4 py-2">{expense.date}</td>
										<td className={`border-2 px-4 py-2 text-white text-center ${expense.is_activated ? "bg-green-700" : "bg-red-700"}`}>{expense.is_activated ? "Activated" : "Deactivated"}</td>
										<td className={`border-2 px-4 py-2 text-white text-center ${getLabel(expense.category) === 'Income' ? 'bg-green-600' :
											getLabel(expense.category) === 'Expense' ? 'bg-red-600' : "bg-blue-600"}`}>
											{getLabel(expense.category)}
										</td>
										<td className='border-2 px-4 py-2 text-center'>
											<Link to={`/expense/details/${expense.id}/`} className="text-white bg-blue-600 mx-2 my-2 p-2 px-4 rounded hover:bg-blue-800">Details</Link>
										</td>
									</tr>
								))}
							</tbody>
						</table>

						{filteredExpenses.length > 0 &&
							<div class="flex flex-col items-center mt-4">
								<span class="text-sm text-gray-700 dark:text-gray-400">
									Showing <span class="font-semibold text-lg text-gray-700 dark:text-white">{(currentPage - 1) * itemsPerPage + 1}</span> to <span class="font-semibold text-gray-900 dark:text-white">{Math.min(currentPage * itemsPerPage, totalEntries)}</span> of <span class="font-semibold text-gray-900 dark:text-white">{totalEntries}</span> Expenses
								</span>
								{/* <!-- Buttons --> */}
								<div class="inline-flex mt-2 xs:mt-0">
									<button onClick={() => goToPreviousPage(currentPage)}
										disabled={currentPage == 1} class="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-s hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
										<svg class="w-3.5 h-3.5 me-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
											<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5H1m0 0 4 4M1 5l4-4" />
										</svg>
										Prev
									</button>

									<div className="flex justify-evenly items-center">
										{Array.from({ length: Math.ceil(totalPages / itemsPerPage) }).map(
											(_, index) => (
												<span
													key={index + 1}
													onClick={() => paginate(index + 1)}
													className={`bg-blue-800 hover:bg-blue-950 text-white rounded-full p-1 w-10 h-10 mx-1 text-center flex justify-center items-center cursor-pointer ${index + 1 === currentPage ? "bg-blue-950" : ""
														}`}
												>
													{index + 1}
												</span>
											)
										)}
									</div>
									<button onClick={() => goToNextPage(currentPage)}
										disabled={currentPage === Math.ceil(totalPages / itemsPerPage)} class="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 border-0 border-s border-gray-700 rounded-e hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
										Next
										<svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
											<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
										</svg>
									</button>
								</div>
							</div>
						}

					</div>
				</>
			}
		</div>
	);
};

export default ExpenseTable;
