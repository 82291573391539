import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

const LoanTable = () => {

    const [loans, setLoans] = useState([]);
    const [loading, setLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [totalEntries, setTotalEntries] = useState(0);

    const userId = localStorage.getItem('contrUserId');
    const groupId = localStorage.getItem('contGroupId');

    const [searchTerm, setSearchTerm] = useState('');
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const activationOptions = [
        { value: 'True', label: 'Returned' },
        { value: 'False', label: 'Not Returned' },
    ];
    const [selectedActivationOption, setSelectedActivationOption] = useState('');

    const handleSelectActivationOptionChange = (e) => {
        setSelectedActivationOption(e.target.value);
    };

    const filteredLoans = loans.filter((loan) => {
        if (searchTerm.trim() === '') {
            return true;
        }

        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const lowerCaseActivatedId = loan.id.toString().toLowerCase();
        const lowerCaseFullname = loan.borrower.Nom.toLowerCase() + loan.borrower.Prenom.toLowerCase();
        const lowerCaseAmount = loan.amount.toString().toLowerCase();
        const lowerCaseDatetaken = loan.date_taken.toString().toLowerCase();
        const lowerCaseDatedue = loan.date_due.toString().toLowerCase();
        const lowerCaseReturned = loan.is_returned ? "Returned" : "Not Returned";

        return (
            lowerCaseActivatedId.includes(lowerCaseSearchTerm) ||
            lowerCaseFullname.includes(lowerCaseSearchTerm) ||
            lowerCaseAmount.includes(lowerCaseSearchTerm) ||
            lowerCaseDatetaken.includes(lowerCaseSearchTerm) ||
            lowerCaseDatedue.includes(lowerCaseSearchTerm) ||
            lowerCaseReturned.toLowerCase().includes(lowerCaseSearchTerm)
        );
    });

    const nbOptions = [
        { label: "1", value: 1 },
        { label: "5", value: 5 },
        { label: "10", value: 10 },
        { label: "20", value: 20 },
        { label: "50", value: 50 },
    ]

    const handleSelectPageNumberChange = (e) => {
        setItemsPerPage(parseInt(e.target.value));
    };

    useEffect(() => {
        const fetchLoans = async () => {
            const cacheBuster = new Date().getTime();
            try {
                let params = {};
                if (selectedActivationOption) {
                    // If only activated selected, fetch with is_activated parameter
                    params = { is_returned: selectedActivationOption };
                } else if (!selectedActivationOption) {
                    // Fetch all expenses for the group ID
                    const allLoansResponse = await axios.get(`https://django.burundientempsreel.com/btr/cotisations/loans/group/${groupId}/?page_size=${itemsPerPage}&page=${currentPage}&cacheBuster=${cacheBuster}`);

                    setTotalEntries(Math.ceil(allLoansResponse.data.count));

                    if (allLoansResponse.data.count > 0) {
                        setLoans(allLoansResponse.data.results);
                        setTotalPages(Math.ceil(allLoansResponse.data.count / itemsPerPage));
                    }
                    return;
                }

                // Fetch expenses based on selected options
                const filteredLoansResponse = await axios.get('https://django.burundientempsreel.com/api/loans/filter/', { params });
                setLoans(filteredLoansResponse.data);

            } catch (error) {
                console.error('Error fetching expenses:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchLoans();
    }, [selectedActivationOption, groupId, currentPage, itemsPerPage]);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const goToPreviousPage = (page) => {
        if (page > 1) {
            setCurrentPage(page - 1);
        }
    };
    const goToNextPage = (page) => {
        if (page < totalPages) {
            setCurrentPage(page + 1);
        }
    };



    return (
        <>
            {loading ? (
                <div className="flex flex-col min-h-screen justify-center items-center">
                    <ClipLoader size={50} color="gray" />
                    <h1>Loading...</h1>
                </div>
            ) : (
                <div className='max-w-screen-xl mx-auto p-4 m-3'>
                    <div className='flex justify-center items-center mb-3'>
                        <h2 className='font-bold text-2xl'>All given loans</h2>
                    </div>
                    <div className='overflow-x-auto w-full p-2 rounded-md shadow-md bg-slate-100'>
                        <div className="flex justify-end items-center flex-wrap mb-4">
                            <div className='flex flex-col justify-center items-start m-1 p-1'>
                                <label htmlFor="searchText" className='text-lg text-gray-800'>Filter Loans</label>
                                <input type="text" id='searchText'
                                    placeholder="Filter loans..."
                                    value={searchTerm}
                                    onChange={handleSearch}
                                    className="border border-gray-400 px-2 py-1 w-full rounded-md"
                                />
                            </div>
                            <div className='flex flex-col justify-center items-start m-1 p-1'>
                                <label htmlFor="selectedActivationOption" className='text-lg text-gray-800'>Select loans by return</label>
                                <select id="selectedActivationOption" value={selectedActivationOption} className='w-full border border-gray-400 rounded-md px-2 py-1'
                                    onChange={handleSelectActivationOptionChange}>
                                    <option value="">Select...</option>
                                    {activationOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                {/* {selectedActivationOption && <p>Selected option: {selectedActivationOption}</p>} */}
                            </div>
                            <div className='flex flex-col justify-center items-start m-1 p-1'>
                                <label htmlFor="selectedPageNumb" className='text-sm text-gray-800'>Number per page</label>
                                <select id="selectedPageNumb" value={itemsPerPage} className='w-full border border-gray-400 rounded-md px-2 py-1'
                                    onChange={handleSelectPageNumberChange}>
                                    <option value="">Number...</option>
                                    {nbOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <table className="table-auto w-full divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="border-2 px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Borrower</th>
                                    <th className="border-2 px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Amount</th>
                                    <th className="border-2 px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Date Taken</th>
                                    <th className="border-2 px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Date Due</th>
                                    <th className="border-2 px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Interest Rate</th>
                                    <th className="border-2 px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Status</th>
                                    <th className="border-2 px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Actions</th>
                                </tr>

                                {filteredLoans.length === 0 && (
                                    <tr>
                                        <th
                                            className='border-2 px-4 py-2 col-span-7 bg-red-800 text-white text-center'
                                            colSpan='7'
                                        >
                                            No loans given for now
                                        </th>
                                    </tr>
                                )}
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {filteredLoans.map((loan) => (
                                    <tr key={loan.id}>
                                        <td className="border-2 px-2 py-1 whitespace-nowrap text-center">{loan.borrower.Nom + " " + loan.borrower.Prenom}</td>
                                        <td className="border-2 px-2 py-1 whitespace-nowrap text-center">{loan.amount}</td>
                                        <td className="border-2 px-2 py-1 whitespace-nowrap text-center">{loan.date_taken}</td>
                                        <td className="border-2 px-2 py-1 whitespace-nowrap text-center">{loan.date_due}</td>
                                        <td className="border-2 px-2 py-1 whitespace-nowrap text-center">{loan.interest_rate}</td>
                                        <td className={`border-2 px-2 py-1 whitespace-nowrap text-white text-center ${loan.is_returned ? "bg-green-700" : "bg-red-700"}`}>{loan.is_returned ? "Returned" : "Not returned"}</td>
                                        <td className="border-2 px-2 py-1 whitespace-nowrap text-center">
                                            <Link to={`/loan/details/${loan.id}`} className="m-1 text-black bg-indigo-300 hover:bg-indigo-500 duration-300 p-1 rounded flex flex-col justify-between items-center">
                                                <span>Details</span>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {filteredLoans.length > 0 &&
                            <div class="flex flex-col items-center mt-4">
                                <span class="text-sm text-gray-700 dark:text-gray-400">
                                    Showing <span class="font-semibold text-lg text-gray-700 dark:text-white">{(currentPage - 1) * itemsPerPage + 1}</span> to <span class="font-semibold text-gray-900 dark:text-white">{Math.min(currentPage * itemsPerPage, totalEntries)}</span> of <span class="font-semibold text-gray-900 dark:text-white">{totalEntries}</span> Entries
                                </span>
                                {/* <!-- Buttons --> */}
                                <div class="inline-flex mt-2 xs:mt-0">
                                    <button onClick={() => goToPreviousPage(currentPage)}
                                        disabled={currentPage == 1} class="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-s hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                        <svg class="w-3.5 h-3.5 me-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5H1m0 0 4 4M1 5l4-4" />
                                        </svg>
                                        Prev
                                    </button>

                                    <div className="flex justify-evenly items-center">
                                        {Array.from({ length: Math.ceil(totalPages / itemsPerPage) }).map(
                                            (_, index) => (
                                                <span
                                                    key={index + 1}
                                                    onClick={() => paginate(index + 1)}
                                                    className={`bg-blue-800 hover:bg-blue-950 text-white rounded-full p-1 w-10 h-10 mx-1 text-center flex justify-center items-center cursor-pointer ${index + 1 === currentPage ? "bg-blue-950" : ""
                                                        }`}
                                                >
                                                    {index + 1}
                                                </span>
                                            )
                                        )}
                                    </div>
                                    <button onClick={() => goToNextPage(currentPage)}
                                        disabled={currentPage === Math.ceil(totalPages / itemsPerPage)} class="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 border-0 border-s border-gray-700 rounded-e hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                        Next
                                        <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            )}
        </>
    );
};

export default LoanTable;
