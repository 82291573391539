import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  FaHome, FaUserFriends, FaMoneyBillAlt, FaCoins,
  FaClipboardList, FaUser, FaBars, FaHandHoldingUsd, FaSignOutAlt
} from 'react-icons/fa';
import { IoStatsChart, IoStatsChartSharp } from "react-icons/io5";
import axios from 'axios';

const Navbar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [groupData, setGroupData] = useState({});

  const userId = localStorage.getItem('contrUserId');
  const groupId = localStorage.getItem('contGroupId');

  // console.log("User id: " + userId);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getContributionGroup() {
      await axios.get(`https://django.burundientempsreel.com/btr/cotisations/get-user-data/${userId}`)
        .then(response => {
          // console.log("User data: ", response.data);
          if (response.data.success) {
            setGroupData(response.data.data);
          } else if (response.data.notFound || response.data.error) {
            setGroupData({});
          }
        }).catch(error => {
          console.log("Error: ", error);
        }).finally(() => { setLoading(false); });
    }
    getContributionGroup();
  }, []);

  const isMobile = windowWidth < 900;
  const isExtraSmall = windowWidth < 540;

  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const isActiveLink = (pathname) => {
    return location.pathname === pathname;
  };

  return (
    <nav className="bg-gray-800 p-2" onClick={() => {
      if (isModalOpen) setIsModalOpen(false)
    }}>
      <div className="max-w-7xl mx-auto px-1">
        <div className="flex justify-between items-center">
          <Link onClick={() => { setIsModalOpen(false) }} to="/" className="text-white font-bold text-xl">
            <div className="flex justify-center items-center rounded-full w-14 h-14 border border-slate-500">
              {loading && <div className="animate-spin rounded-full h-auto w-7 border-2 border-blue-800"></div>}
              <img onLoad={() => { setLoading(false) }} className="rounded-full w-full h-full object-contain"
                src={`${groupData.profile_picture}`} alt={groupData.username} />
            </div>
          </Link>

          <div className="flex space-x-4">
            {isMobile ? (
              <>
                {isExtraSmall ? (
                  <div className='flex flex-col justify-center items-center'>
                    <button onClick={handleToggleModal} className="text-white bg-orange-600 hover:bg-orange-800 py-2 px-2 rounded-md">
                      <FaBars size={25} color='white' />
                    </button>

                    {isModalOpen && isExtraSmall && (
                      <div className="modal-content1 fixed inset-0 top-[72px] bg-gray-800 bg-opacity-75 flex justify-end items-start z-50">
                        <div className="bg-white p-4 rounded-md">
                          <div className="flex flex-col space-y-2">
                            <Link onClick={() => { setIsModalOpen(false) }} to="/" className={`text-gray-800 py-2 px-2 rounded-md ${isActiveLink('/') ? 'bg-orange-500' : ''}`}>
                              <FaHome /> Home
                            </Link>
                            <Link onClick={() => { setIsModalOpen(false) }} to="/members/view/all/" className={`text-gray-800 py-2 px-2 rounded-md ${isActiveLink('/members/view/all/') ? 'bg-orange-500' : ''}`}>
                              <FaUserFriends /> Members
                            </Link>
                            <Link onClick={() => { setIsModalOpen(false) }} to="/contribution-type/view/all" className={`text-gray-800 py-2 px-2 rounded-md ${isActiveLink('/contribution-type/view/all') ? 'bg-orange-500' : ''}`}>
                              <FaMoneyBillAlt /> Contribution Types
                            </Link>
                            <Link onClick={() => { setIsModalOpen(false) }} to="/contributions/view/all/" className={`text-gray-800 py-2 px-2 rounded-md ${isActiveLink('/contributions/view/all/') ? 'bg-orange-500' : ''}`}>
                              <FaCoins /> Contributions
                            </Link>
                            <Link onClick={() => { setIsModalOpen(false) }} to="/expenses/view/all/" className={`text-gray-800 py-2 px-2 rounded-md ${isActiveLink('/expenses/view/all/') ? 'bg-orange-500' : ''}`}>
                              <FaClipboardList /> Expenses
                            </Link>
                            <Link onClick={() => { setIsModalOpen(false) }} to="/view-all/loans/" className={`text-gray-800 py-2 px-2 rounded-md ${isActiveLink('/view-all/loans/') ? 'bg-orange-500' : ''}`}>
                              <FaHandHoldingUsd /> Loans
                            </Link>
                            <Link onClick={() => { setIsModalOpen(false) }} to="/statistics/report/" className={`text-gray-800 py-2 px-2 rounded-md ${isActiveLink('/statistics/report/') ? 'bg-orange-500' : ''}`}>
                              <IoStatsChartSharp />  Report
                            </Link>
                            <Link onClick={() => { setIsModalOpen(false) }} to="/view/my-account" className={`text-gray-800 py-2 px-2 rounded-md ${isActiveLink('/view/my-account') ? 'bg-orange-500' : ''}`}>
                              <FaUser /> Account
                            </Link>

                            <div className='h-1 bg-slate-500 w-full' />
                            <button onClick={() => {
                              setIsModalOpen(false);

                              localStorage.removeItem("contrToken");
                              localStorage.removeItem("contrUserId");
                              props.setIsLoading1(true);

                              console.log("Deconnected successfully!");

                            }} className="hover:text-gray-800 text-white bg-indigo-800 hover:bg-indigo-500 py-2 px-2 rounded-md flex flex-col justify-center items-center">
                              <FaSignOutAlt /> Logout
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <Link onClick={() => { setIsModalOpen(false) }} to="/" title='Home' className={`text-white hover:bg-indigo-500 py-1 px-1 rounded-md ${isActiveLink('/') ? 'bg-orange-500' : ''}`}>
                      <FaHome size={30} />
                    </Link>
                    <Link onClick={() => { setIsModalOpen(false) }} to="/members/view/all/" title='All members' className={`text-white hover:bg-indigo-500 py-1 px-1 rounded-md ${isActiveLink('/members/view/all/') ? 'bg-orange-500' : ''}`}>
                      <FaUserFriends size={30} />
                    </Link>
                    <Link onClick={() => { setIsModalOpen(false) }} to="/contribution-type/view/all" title='All contribution types' className={`text-white hover:bg-indigo-500 py-1 px-1 rounded-md ${isActiveLink('/contribution-type/view/all') ? 'bg-orange-500' : ''}`}>
                      <FaMoneyBillAlt size={30} />
                    </Link>
                    <Link onClick={() => { setIsModalOpen(false) }} to="/contributions/view/all/" title='All contributions' className={`text-white hover:bg-indigo-500 py-1 px-1 rounded-md ${isActiveLink('/contributions/view/all/') ? 'bg-orange-500' : ''}`}>
                      <FaCoins size={30} />
                    </Link>
                    <Link onClick={() => { setIsModalOpen(false) }} to="/expenses/view/all/" title='All expenses' className={`text-white hover:bg-indigo-500 py-1 px-1 rounded-md ${isActiveLink('/expenses/view/all/') ? 'bg-orange-500' : ''}`}>
                      <FaClipboardList size={30} />
                    </Link>
                    <Link onClick={() => { setIsModalOpen(false) }} to="/view-all/loans/" title='All loans' className={`text-white hover:bg-indigo-500 py-1 px-1 rounded-md ${isActiveLink('/view-all/loans/') ? 'bg-orange-500' : ''}`}>
                      <FaHandHoldingUsd size={30} />
                    </Link>
                    <Link onClick={() => { setIsModalOpen(false) }} to="/statistics/report/" title='Report or Statistics' className={`text-white hover:bg-indigo-500 py-1 px-1 rounded-md ${isActiveLink('/statistics/report/') ? 'bg-orange-500' : ''}`}>
                      <IoStatsChartSharp size={30} />
                    </Link>
                    <div className="relative inline-block">
                      <div>
                        <button onClick={handleToggleModal} title='Settings' type="button" className={`text-white flex flex-col justify-center items-center hover:bg-indigo-500 py-1 px-1 rounded-md ${isActiveLink('/view/my-account') ? 'bg-orange-500' : ''}`}>
                          <FaUser size={30} />
                        </button>
                      </div>
                      {isModalOpen && (
                        <div className="modal-content2 origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" tabIndex="-1" id="menu-item-1" aria-orientation="vertical" aria-labelledby="menu-button">
                          <div className="py-1" role="none">
                            <Link to="/view/my-account" onClick={() => { setIsModalOpen(false) }} className={`block text-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 ${isActiveLink('/view/my-account') ? 'bg-orange-500' : ''}`} role="menuitem" tabIndex="-1" id="menu-item-1">Settings</Link>
                            <div className='w-full'>
                              <button onClick={() => {
                                setIsModalOpen(false);

                                localStorage.removeItem("contrToken");
                                localStorage.removeItem("contrUserId");
                                props.setIsLoading1(true);
                                navigate('/');

                                console.log("Deconnected successfully!");
                              }} role='menuitem' className={`w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 ${isActiveLink('/') ? 'bg-orange-500' : ''}`}>Logout</button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </>
            ) :
              <>
                <Link onClick={() => { setIsModalOpen(false) }} to="/" className={`text-white flex flex-col justify-center items-center hover:bg-indigo-500 py-2 px-2 rounded-md ${isActiveLink('/') ? 'bg-orange-500' : ''}`}>
                  <FaHome size={25} />
                  <span>Home</span>
                </Link>
                <Link onClick={() => { setIsModalOpen(false) }} to="/members/view/all/" className={`text-white flex flex-col justify-center items-center hover:bg-indigo-500 py-2 px-2 rounded-md ${isActiveLink('/members/view/all/') ? 'bg-orange-500' : ''}`}>
                  <FaUserFriends size={25} />
                  <span>Members</span>
                </Link>
                <Link onClick={() => { setIsModalOpen(false) }} to="/contribution-type/view/all" className={`text-white flex flex-col justify-center items-center hover:bg-indigo-500 py-2 px-2 rounded-md ${isActiveLink('/contribution-type/view/all') ? 'bg-orange-500' : ''}`}>
                  <FaMoneyBillAlt size={25} />
                  <span>Contribution Types</span>
                </Link>
                <Link onClick={() => { setIsModalOpen(false) }} to="/contributions/view/all/" className={`text-white flex flex-col justify-center items-center hover:bg-indigo-500 py-2 px-2 rounded-md ${isActiveLink('/contributions/view/all/') ? 'bg-orange-500' : ''}`}>
                  <FaCoins size={25} />
                  <span>Contributions</span>
                </Link>
                <Link onClick={() => { setIsModalOpen(false) }} to="/expenses/view/all/" className={`text-white flex flex-col justify-center items-center hover:bg-indigo-500 py-2 px-2 rounded-md ${isActiveLink('/expenses/view/all/') ? 'bg-orange-500' : ''}`}>
                  <FaClipboardList size={25} />
                  <span>Expenses</span>
                </Link>
                <Link onClick={() => { setIsModalOpen(false) }} to="/view-all/loans/" className={`text-white flex flex-col justify-center items-center hover:bg-indigo-500 py-2 px-2 rounded-md ${isActiveLink('/view-all/loans/') ? 'bg-orange-500' : ''}`}>
                  <FaHandHoldingUsd size={25} />
                  <span>Loans</span>
                </Link>
                <Link onClick={() => { setIsModalOpen(false) }} to="/statistics/report/" className={`text-white flex flex-col justify-center items-center hover:bg-indigo-500 py-2 px-2 rounded-md ${isActiveLink('/statistics/report/') ? 'bg-orange-500' : ''}`}>
                  <IoStatsChart size={25} />
                  <span>Report</span>
                </Link>

                <div className="relative inline-block">
                  <div>
                    <button onClick={handleToggleModal} type="button" className={`text-white flex flex-col justify-center items-center hover:bg-indigo-500 py-2 px-2 rounded-md ${isActiveLink('/view/my-account') ? 'bg-orange-500' : ''}`}>
                      <FaUser size={25} />
                      <span>Account</span>
                    </button>
                  </div>
                  {isModalOpen && (
                    <div className="modal-content2 origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" tabIndex="-1" id="menu-item-1" aria-orientation="vertical" aria-labelledby="menu-button">
                      <div className="py-1" role="none">
                        <Link to="/view/my-account" onClick={() => { setIsModalOpen(false) }} className={`block text-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 ${isActiveLink('/view/my-account') ? 'bg-orange-500' : ''}`} role="menuitem" tabIndex="-1" id="menu-item-1">Settings</Link>
                        <div className='w-full'>
                          <button onClick={() => {
                            setIsModalOpen(false);

                            localStorage.removeItem("contrToken");
                            localStorage.removeItem("contrUserId");
                            props.setIsLoading1(true);
                            navigate('/');

                            console.log("Deconnected successfully!");
                          }} role='menuitem' className={`w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 ${isActiveLink('/') ? 'bg-orange-500' : ''}`}>Logout</button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
