import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import SpinnerOverlay from '../Other/Loader';
import EmptyPage from '../Other/Empty';
import axios from 'axios';
import Swal from 'sweetalert2';

const ExpenseDetailsCard = () => {

    const text = "details for this expense"

    const { id } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [expenseDetails, setExpenseDetails] = useState({});

    useEffect(() => {
        async function getContributionDetails() {
            const cacheBuster = new Date().getTime();

            axios.get(`https://django.burundientempsreel.com/btr/cotisations/expense/${id}/details/?cacheBuster=${cacheBuster}`)
                .then(response => {
                    // console.log('Response:', response.data);
                    if (response.data) {
                        setExpenseDetails(response.data);
                    }
                })
                .catch(error => {
                    console.log('Error:', error);
                }).finally(() => { setLoading(false); });
        }
        getContributionDetails();
    }, [id])

    const handleActivateExpense = (contrId) => {
        console.log("Activating expense id: ", contrId);
        Swal.fire({
            title: "Do you want to activate this expense?",
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.patch(`https://django.burundientempsreel.com/btr/expenses/${contrId}/status/`, {
                    "is_activated": true
                })
                    .then(response => {
                        // console.log("Del res: ", response.data);
                        toast.success('Expense activated successfully.', { autoClose: 3000, position: "top-right" });
                        navigate('/expenses/view/all/');
                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error activating expense:', error);
                        // Display an error message or perform any additional actions
                    });
            } else {
                console.log("Deletion canceled!");
            }
        });
    }

    const handleNotDeactivateExpense = (contrId) => {
        console.log("Deactivating expense id: ", contrId);
        Swal.fire({
            title: "Do you want to deactivate this expense?!",
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.patch(`https://django.burundientempsreel.com/btr/expenses/${contrId}/status/`, {
                    "is_activated": false
                })
                    .then(response => {
                        // console.log("Del res: ", response.data);
                        toast.success('Expense deactivated successfully.', { autoClose: 3000, position: "top-right" });
                        navigate('/expenses/view/all/');
                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error deactivating expense:', error);
                        // Display an error message or perform any additional actions
                    });
            } else {
                console.log("Deactivating canceled!");
            }
        });
    }

    const handleDeleteExpense = () => {
        Swal.fire({
            title: "Do you want to delete this expense? If you are not sure, you may deactivate it!",
            showCancelButton: true,
            confirmButtonText: "Delete",
            icon: "warning",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.delete(`https://django.burundientempsreel.com/btr/cotisations/expense/${id}/delete/`)
                    .then(response => {
                        // Handle successful deletion
                        // console.log("Del res: ", response.data);
                        toast.success('Expense deleted successfully', { autoClose: 3000, position: "top-right" });
                        navigate('/expenses/view/all/');
                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error deleting expense:', error);
                        // Display an error message or perform any additional actions
                    });
            } else {
                console.log("Deletion canceled!");
            }
        });
    };


    return (
        <div className='max-w-screen-xl mx-auto p-4 m-3 bg-slate-100'>
            <SpinnerOverlay isLoading={loading} />

            {Object.keys(expenseDetails).length > 0 ?
                <div className='flex flex-col'>

                    <div className='flex justify-start items-center mb-3'>
                        <Link to={`/expenses/view/all/`}
                            className="px-4 py-2 bg-blue-500 text-white rounded-md shadow-md text-center"
                        >
                            Return
                        </Link>
                    </div>

                    <div className='flex justify-center items-center mb-1'>
                        <h2 className='font-bold text-2xl'>Expense details</h2>
                    </div>

                    <div className='flex justify-center items-center flex-wrap p-2'>
                        {/* Member details */}
                        <div className="px-6 w-fit py-4  m-3 rounded-md shadow-md bg-gray-200 flex flex-col justify-center items-center">
                            <div className="font-bold text-xl mb-2">User Details</div>
                            <div className='flex flex-wrap justify-center items-center'>
                                <div className="w-20 h-20 flex justify-center items-center rounded-full border border-slate-500">
                                    <img src={`${expenseDetails.user.profile_picture}`} className='w-full h-full rounded-full object-cover' />
                                </div>
                                <div className='m-1 p-1'>
                                    <p><span className="font-semibold">Name:</span> {expenseDetails.user.Nom} {expenseDetails.user.Prenom}</p>
                                    <p><span className="font-semibold">Email:</span> <a className='text-blue-600' href={`mailto:${expenseDetails.user.email}`}>{expenseDetails.user.email}</a></p>
                                    {expenseDetails.user.phone_number && <p><span className="font-semibold">Phone Number:</span> <a className='text-blue-600' href={`tel:${expenseDetails.user.phone_number}`}>{expenseDetails.user.phone_number}</a></p>}
                                </div>
                            </div>
                        </div>

                        {/* Group details */}
                        <div className="px-6 py-4 w-fit m-3 rounded-md shadow-md bg-gray-200 flex flex-col justify-center items-center">
                            <div className="font-bold text-xl mb-2">Group Details</div>
                            <div className='flex flex-wrap justify-center items-center'>
                                <div className="w-16 h-16 flex justify-center items-center rounded-full border border-slate-500">
                                    <img src={`${expenseDetails.group.logo}`} className='w-full h-full rounded-full object-contain' />
                                </div>
                                <div className='m-1 p-1'>
                                    <p><span className="font-semibold">Name:</span> {expenseDetails.group.name}</p>
                                    <p><span className="font-semibold">Slogan:</span> {expenseDetails.group.slogan}</p>
                                </div>
                            </div>
                        </div>

                        {/* Contribution details */}
                        <div className="px-6 py-4 w-fit m-3 rounded-md shadow-md bg-red-200 border-2 border-red-700 flex flex-col justify-center items-center">
                            <div className="font-bold text-xl mb-2">Expense Details</div>
                            <div className="flex flex-wrap justify-center items-center">
                                <div className="w-32 h-32 flex justify-center items-center rounded-full border border-slate-500 m-1">
                                    <img src={`${expenseDetails.proof_image}`} className='w-full h-full rounded-full object-cover' />
                                </div>
                                <div className='m-1 p-1'>
                                    <p><span className="font-semibold">Title:</span> {expenseDetails.title}</p>
                                    <p><span className="font-semibold">Amount:</span> {expenseDetails.amount}</p>
                                    <p><span className="font-semibold">Payment Date:</span> {expenseDetails.payment_date}</p>
                                    <p><span className="font-semibold">Details:</span> {expenseDetails.description}</p>
                                    <p className={`p-1 rounded-md text-white ${expenseDetails.is_activated ? "bg-green-800" : "bg-red-800"}`}>
                                        <span className="font-semibold">Expense activated:</span> {expenseDetails.is_activated ? <span className=''>Yes</span> :
                                            <span className=''>No</span>}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap justify-end mt-4">
                        <button
                            className="px-4 py-2 m-1 bg-green-500 hover:bg-green-700 text-white rounded-md shadow-md text-center"
                            onClick={() => handleActivateExpense(expenseDetails.id)}
                        >
                            Activate
                        </button>
                        <button
                            className="px-4 py-2 m-1 bg-orange-500 hover:bg-orange-700 text-white rounded-md shadow-md  text-center"
                            onClick={() => handleNotDeactivateExpense(expenseDetails.id)}
                        >
                            Deactivate
                        </button>
                        <button
                            className="px-4 py-2 m-1 bg-red-500 hover:bg-red-700 text-white rounded-md shadow-md  text-center"
                            onClick={handleDeleteExpense}
                        >
                            Delete
                        </button>
                        <Link unselectable='off' to={`/expense/${expenseDetails.id}/edit`}
                            className="px-4 py-2 m-1 bg-blue-500 hover:bg-blue-700 text-white rounded-md shadow-md text-center"
                        >
                            Edit Expense
                        </Link>
                    </div>
                </div>
                :
                <EmptyPage text={text} />
            }
        </div>
    );
};

export default ExpenseDetailsCard;
