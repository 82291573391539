import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import SpinnerOverlay from '../Other/Loader';

const ContributionTable = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [contributions, setContributions] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);

  const groupId = localStorage.getItem("contGroupId");
  const userId = localStorage.getItem('contrUserId');

  // console.log("Group id:: ", groupId);

  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const activationOptions = [
    { value: 'True', label: 'Activated' },
    { value: 'False', label: 'Deactivated' },
  ];
  const [selectedActivationOption, setSelectedActivationOption] = useState('');

  const handleSelectActivationOptionChange = (e) => {
    setSelectedActivationOption(e.target.value);
  };

  const nbOptions = [
    { label: "1", value: 1 },
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
  ]

  const handleSelectPageNumberChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
  };

  useEffect(() => {
    const fetchContributions = async () => {
      const cacheBuster = new Date().getTime();
      try {
        let params = {};
        // console.log("Optio: ", typeof selectedActivationOption);
        if (selectedActivationOption) {
          // If only activated selected, fetch with is_activated parameter
          params = { is_activated: selectedActivationOption };
        }
        else if (selectedActivationOption.trim() === "") {
          // Fetch all expenses for the group ID
          const allContributionsResponse = await axios.get(`https://django.burundientempsreel.com/btr/cotisations/contributions/${groupId}/?page_size=${itemsPerPage}&page=${currentPage}&cacheBuster=${cacheBuster}`);

          setTotalEntries(Math.ceil(allContributionsResponse.data.count));
          if (allContributionsResponse.data.count > 0) {
            setContributions(allContributionsResponse.data.results);
            setTotalPages(Math.ceil(allContributionsResponse.data.count / itemsPerPage));
          }
        }

        if (Object.keys(params).length > 0) {
          // Fetch expenses based on selected options
          const filteredContributionsResponse = await axios.get('https://django.burundientempsreel.com/api/contributions/filter/', { params });
          setContributions(filteredContributionsResponse.data);
        }

      } catch (error) {
        console.error('Error fetching expenses:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchContributions();
  }, [selectedActivationOption, groupId, currentPage, itemsPerPage]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const goToPreviousPage = (page) => {
    if (page > 1) {
      setCurrentPage(page - 1);
    }
  };
  const goToNextPage = (page) => {
    if (page < totalPages) {
      setCurrentPage(page + 1);
    }
  };

  const filteredContributions = contributions.filter((contribution) => {
    if (searchTerm.trim() === '') {
      return true;
    }

    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const lowerCaseActivatedId = contribution.id.toString().toLowerCase();
    const lowerCaseFullname = contribution.member.Nom.toLowerCase() + contribution.member.Prenom.toLowerCase();
    const lowerCaseDate = contribution.payment_date.toString().toLowerCase();
    const lowerCaseActivated = contribution.is_activated ? "Activated" : "Deactivated";
    const lowerCaseAmount = contribution.amount.toString().toLowerCase();

    return (
      lowerCaseActivatedId.includes(lowerCaseSearchTerm) ||
      lowerCaseFullname.includes(lowerCaseSearchTerm) ||
      lowerCaseDate.includes(lowerCaseSearchTerm) ||
      lowerCaseActivated.toLowerCase().includes(lowerCaseSearchTerm) ||
      lowerCaseAmount.includes(lowerCaseSearchTerm)
    );
  });

  return (
    <div className='max-w-screen-xl mx-auto p-4 m-3'>
      <SpinnerOverlay isLoading={loading} />
      {!loading &&
        <>
          <div className='flex justify-center items-center mb-3'>
            <h2 className='font-bold text-2xl'>All Contributions</h2>
          </div>

          <div className='overflow-x-auto w-full p-2 rounded-md shadow-md bg-slate-100'>
            <div className="flex justify-end items-center flex-wrap mb-4 w-full">
              <div className='flex flex-col justify-center items-start m-1 p-1'>
                <label htmlFor="searchText" className='text-lg text-gray-800'>Filter contributions</label>
                <input type="text" id='searchText'
                  placeholder="Filter contributions..."
                  value={searchTerm}
                  onChange={handleSearch}
                  className="border border-gray-400 px-2 py-1 w-full rounded-md"
                />
              </div>
              <div className='flex flex-col justify-center items-start m-1 p-1'>
                <label htmlFor="selectedActivationOption" className='text-lg text-gray-800'>Contributions by return</label>
                <select id="selectedActivationOption" value={selectedActivationOption} className='w-full border border-gray-400 rounded-md px-2 py-1'
                  onChange={handleSelectActivationOptionChange}>
                  <option value="">Select...</option>
                  {activationOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {/* {selectedActivationOption && <p>Selected option: {selectedActivationOption}</p>} */}
              </div>
              <div className='flex flex-col justify-center items-start m-1 p-1'>
                <label htmlFor="selectedPageNumb" className='text-sm text-gray-800'>Number per page</label>
                <select id="selectedPageNumb" value={itemsPerPage} className='w-full border border-gray-400 rounded-md px-2 py-1'
                  onChange={handleSelectPageNumberChange}>
                  <option value="">Number...</option>
                  {nbOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <table className='table-auto w-full border rounded-md'>
              <thead>
                <tr>
                  <th className='border-2 px-4 py-2'>Member</th>
                  {/* <th className='border-2 px-4 py-2'>Contribution Type</th> */}
                  <th className='border-2 px-4 py-2'>Amount</th>
                  <th className='border-2 px-4 py-2'>Payment Date</th>
                  <th className='border-2 px-4 py-2'>Activated</th>
                  <th className='border-2 px-4 py-2'>Actions</th>
                </tr>
                {filteredContributions.length === 0 && (
                  <tr>
                    <th
                      className='border-2 px-4 py-2 col-span-5 bg-red-800 text-white text-center'
                      colSpan='6'
                    >
                      No contributions made
                    </th>
                  </tr>
                )}
              </thead>
              <tbody>
                {filteredContributions.map((contribution) => (
                  <tr key={contribution.id}>
                    <td className='border-2 px-3 py-2 text-center'>{contribution.member.Nom + " " + contribution.member.Prenom}</td>
                    {/* <td className='border-2 px-4 py-2 text-center'>
                  {contribution.contribution_type.name}
                </td> */}
                    <td className='border-2 px-4 py-2 text-center'>{contribution.amount}</td>
                    <td className='border-2 px-4 py-2 text-center'>
                      {new Date(contribution.payment_date).toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </td>
                    <td className={`border-2 px-4 py-2 text-white text-center ${contribution.is_activated ? "bg-green-700" : "bg-red-700"}`}>{contribution.is_activated ? "Activated" : "Deactivated"}</td>
                    <td className='border-2 px-4 py-2 text-center'>
                      <Link to={`/contributions/details/${contribution.id}/`} className="text-white bg-blue-600 mx-2 my-2 p-2 px-4 rounded hover:bg-blue-800">Details</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {filteredContributions.length > 0 &&
              <div class="flex flex-col items-center mt-4">
                <span class="text-sm text-gray-700 dark:text-gray-400">
                  Showing <span class="font-semibold text-lg text-gray-700 dark:text-white">{(currentPage - 1) * itemsPerPage + 1}</span> to <span class="font-semibold text-gray-900 dark:text-white">{Math.min(currentPage * itemsPerPage, totalEntries)}</span> of <span class="font-semibold text-gray-900 dark:text-white">{totalEntries}</span> Entries
                </span>
                {/* <!-- Buttons --> */}
                <div class="inline-flex mt-2 xs:mt-0">
                  <button onClick={() => goToPreviousPage(currentPage)}
                    disabled={currentPage == 1} class="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-s hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                    <svg class="w-3.5 h-3.5 me-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5H1m0 0 4 4M1 5l4-4" />
                    </svg>
                    Prev
                  </button>

                  <div className="flex justify-evenly items-center">
                    {Array.from({ length: Math.ceil(totalPages / itemsPerPage) }).map(
                      (_, index) => (
                        <span
                          key={index + 1}
                          onClick={() => paginate(index + 1)}
                          className={`bg-blue-800 hover:bg-blue-950 text-white rounded-full p-1 w-10 h-10 mx-1 text-center flex justify-center items-center cursor-pointer ${index + 1 === currentPage ? "bg-blue-950" : ""
                            }`}
                        >
                          {index + 1}
                        </span>
                      )
                    )}
                  </div>
                  <button onClick={() => goToNextPage(currentPage)}
                    disabled={currentPage === Math.ceil(totalPages / itemsPerPage)} class="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 border-0 border-s border-gray-700 rounded-e hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                    Next
                    <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                    </svg>
                  </button>
                </div>
              </div>
            }

          </div>
        </>
      }
    </div>
  );
};

export default ContributionTable;
