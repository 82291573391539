import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { Link, useParams, useNavigate } from 'react-router-dom';

import ContributionTypeForm from '../Contribution-Types/ContributionType';
import SpinnerOverlay from '../Other/Spinner';

const ContributionForm = (props) => {
  const navigate = useNavigate();

  const [amount, setAmount] = useState('');
  const [details, setDetails] = useState('');

  const groupId = localStorage.getItem("contGroupId");
  const userId = localStorage.getItem('contrUserId');

  const [contributionTypes, setContributionTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);

  const [typeId, setTypeId] = useState("");

  const [member, setMember] = useState({});
  const [contributionType, setContributionType] = useState(false);

  useEffect(() => {
    // Fetch data from the endpoint
    axios.get(`https://django.burundientempsreel.com/btr/cotisations/contribution-types/group/${groupId}`)
      .then(response => {
        // console.log("Res data: ", response.data);
        if (response.data.length > 0) {
          setContributionTypes(response.data);
          setContributionType(false);
        } else {
          setContributionType(true);
        }
      })
      .catch(error => {
        console.error('Error fetching contribution types:', error);
      });
  }, []);

  useEffect(() => {
    // Fetch data from the endpoint
    axios.get(`https://django.burundientempsreel.com/btr/cotisations/member/details/${props.memberId}/`)
      .then(response => {
        setMember(response.data);
      })
      .catch(error => {
        console.error('Error fetching contribution types:', error);
      });
  }, [props.memberId]);

  const [sending, setSending] = useState(false);

  const handleSelectType = (type) => {
    setSelectedType(type);
    setTypeId(type.id);
    // console.log("Selected id: ", type.id);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (amount.trim() === "") {
      toast.warning("The amount to contribution is required");
      return;
    }
    if (typeId.toString().trim() === "") {
      toast.warning("Please select the contribution type");
      return;
    }

    const formData = {
      "group": parseInt(groupId),
      "user": parseInt(userId),
      "member": parseInt(props.memberId),
      "contribution_type": parseInt(typeId),
      "amount": parseFloat(amount),
      "details": details,
    };

    console.log("FormData: ", formData);

    setSending(true);

    axios.post('https://django.burundientempsreel.com/btr/cotisations/contribution/create/', formData)
      .then(response => {
        if (response.data.success) {
          toast.success(`Contribution added successfully`);
          navigate("/contributions/view/all/");

          // Clear form after successful submission (optional)
          setAmount(''); setDetails(''); setTypeId("");
        } else {
          console.log("Add contrbution res: ", response.data)
        }
      })
      .catch(error => {
        console.error(error);
        toast.error('An error occurred. Please try again.'); // Use toast.error for error message
      }).finally(() => { setSending(false); })
  };

  const closeContributionForm = () => {
    props.setContribute(false);
  }

  return (
    <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-80">
      <div className='max-w-screen-sm w-full p-4 m-3 rounded-md shadow-md bg-gray-200'>
        <div className='flex justify-between items-center mb-3'>
          <h2 className='font-bold text-xl'>Add a new Contribution to <span className="text-indigo-700">{member.Nom + " " + member.Prenom}</span></h2>
          <button onClick={closeContributionForm} className='bg-blue-800 p-1 rounded-full w-10 h-10 cursor-pointer'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" /></svg>
          </button>
        </div>
        {contributionTypes.length > 0 ?
          <>
            <div className='flex justify-start items-center mb-3'>
              <h2 className='font-semibold text-md text-center'>Select Contribution Type below<span className='text-red-700 font-bold text-xl'>*</span></h2>
            </div>
            <div className="flex justify-between items-center flex-wrap mb-2">
              {contributionTypes.map(type => (
                <div
                  key={type.id}
                  className={`bg-blue-300 w-[15em] h-[5em] mx-2 hover:bg-blue-600 shadow-md rounded-md p-2 cursor-pointer border-2 ${selectedType && selectedType.id === type.id ? 'border-red-800 bg-blue-600' : 'border-gray-300'
                    }`}
                  onClick={() => handleSelectType(type)}
                >
                  <h3 className="font-bold text-lg">{type.name}</h3>
                  <p className="text-sm">{type.description}</p>
                </div>
              ))}
            </div>
          </> :
          <div className='flex p-2 flex-col justify-start mb-3 items-start'>
            <p>A contriubution type is needed to specify if the member is contrbuting MONTHLY, QUARTERLY, SEMESTER, or ANNUAL</p>
            <button className='bg-blue-700 text-white p-2 rounded-md'>Create a type of contribution</button>
          </div>
        }

        <div className='border border-orange-700 rounded-md p-4'>
          <form onSubmit={handleSubmit} className=''>

            <div className='flex flex-col my-2'>
              <label htmlFor='amount' className='text-md font-semibold mx-1'>
                Amount<span className='text-red-700 font-bold text-xl'>*</span>
              </label>
              <input
                type='number'
                id='amount'
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className='rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500'

              />
            </div>
            <div className='flex flex-col my-2'>
              <label htmlFor='details' className='text-md font-semibold mx-1'>
                Details or description (Optional):
              </label>
              <textarea
                id='details'
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                className='rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500'
              />
            </div>

            <div className="flex justify-end items-center self-end col-span-2">
              <div className="col-span-full flex justify-self-end">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center justify-center"
                >
                  <span className="mr-2">Create Contribution</span>
                </button>
              </div>
            </div>
          </form>
        </div>

        {contributionType && <ContributionTypeForm setContributionType={setContributionType} />}
        <SpinnerOverlay isLoading={sending} />

      </div>
    </div>
  );
};

export default ContributionForm;
