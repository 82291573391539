import React, { useState, useEffect } from 'react';
import SpinnerOverlay from '../Other/Loader';

const ContributionGroupTable = () => {

  const [contributionGroups, setContributionGroups] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getContributionGroups() {
      fetch(`https://django.burundientempsreel.com/btr/cotisations/contribution_groups/`)
        .then(response => response.json())
        .then(data => {
          setContributionGroups(data)
        }).finally(() => { setLoading(false); })
    }
    getContributionGroups();
  }, []);

  return (
    <div className='max-w-full mx-auto p-4'>
      <SpinnerOverlay isLoading={loading} />
      {!loading &&
        <>
          <div className='flex justify-center items-center mb-3'>
            <h2 className='font-bold text-2xl'>All Contribution Groups</h2>
          </div>
          <div className='overflow-x-auto'>
            <table className='table-auto w-full'>
              <thead>
                <tr>
                  <th className='border-2 px-4 py-2'>Name</th>
                  <th className='border-2 px-4 py-2'>Security Code</th>
                  <th className='border-2 px-4 py-2'>Slogan</th>
                </tr>
                {contributionGroups.length === 0 && (
                  <tr>
                    <th
                      className='border-2 px-4 py-2 col-span-5 bg-red-800 text-white'
                      colSpan='5'
                    >
                      No contribution groups created
                    </th>
                  </tr>
                )}
              </thead>
              <tbody>
                {contributionGroups.map((group) => (
                  <tr key={group.id}>
                    <td className='border-2 px-4 py-2'>{group.name}</td>
                    <td className='border-2 px-4 py-2'>{group.code}</td>
                    <td className='border-2 px-4 py-2'>{group.slogan}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      }
    </div>
  );
};

export default ContributionGroupTable;
