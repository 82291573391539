import React, { useState, useEffect } from 'react';
import axios from 'axios';

import ContributionTypeForm from './ContributionType';
import SpinnerOverlay from '../Other/Loader';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

const ContributionTypeTable = () => {
  const [contributionTypes, setContributionTypes] = useState([]);
  const [addType, setAddType] = useState(false);
  const [loading, setLoading] = useState(true);

  const groupId = localStorage.getItem("contGroupId");

  useEffect(() => {
    const fetchContributionTypes = async () => {
      const cacheBuster = new Date().getTime();
      try {
        const response = await axios.get(`https://django.burundientempsreel.com/btr/cotisations/contribution-types/group/${groupId}/?cacheBuster=${cacheBuster}`);
        // console.log("Res data: ", response.data);
        if (response.data.length > 0) {
          setContributionTypes(response.data);
        }
      } catch (error) {
        console.error('Error fetching contribution types:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchContributionTypes();
  }, [addType]);

  const handleContributionType = () => { setAddType(!addType); };

  const handleDelete = (contrTypeId) => {
    // console.log("Deleting contr type id: ", contrTypeId);
    Swal.fire({
      title: "Do you want to delete this contribution type?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios.delete(`https://django.burundientempsreel.com/btr/cotisations/contribution-types/delete/${contrTypeId}/`)
          .then(response => {
            console.log("Del res: ", response.data);
            toast.success('Contribution type deleted successfully.', { autoClose: 3000, position: "top-right" });

            // Remove the deleted item from the contributionTypes list
            setContributionTypes(prevTypes => prevTypes.filter(type => type.id !== contrTypeId));
          })
          .catch(error => {
            // Handle error
            console.error('Error deleting contr type:', error);
            // Display an error message or perform any additional actions
          });
      } else {
        console.log("Deletion canceled!");
      }
    });
  }

  return (
    <div className='max-w-full mx-auto p-4'>
      <SpinnerOverlay isLoading={loading} />
      {!loading &&
        <>
          <div className='flex flex-wrap justify-between items-center mb-3'>
            <h2 className='font-bold text-2xl'>Available Contribution Types</h2>
            <button onClick={handleContributionType} className="text-white bg-blue-600 mx-2 my-2 p-2 px-4 rounded hover:bg-blue-800">Add another type</button>
          </div>

          <div className='w-full'>
            <table className='table-auto w-full p-2 rounded-md shadow-md bg-slate-100'>
              <thead className='bg-slate-400'>
                <tr>
                  <th className='border-2 px-4 py-2'>Name</th>
                  <th className='border-2 px-4 py-2'>Description</th>
                  <th className='border-2 px-4 py-2'>Actions</th>
                </tr>
                {contributionTypes.length === 0 && (
                  <tr>
                    <th
                      className='border-2 px-4 py-2 col-span-5 bg-red-800 text-white'
                      colSpan='6'
                    >
                      No contributions type added for now!
                    </th>
                  </tr>
                )}
              </thead>
              <tbody>
                {contributionTypes.map((contributionType) => (
                  <tr key={contributionType.id}>
                    <td className='border-2 px-4 py-2 text-center'>{contributionType.name}</td>
                    <td className='border-2 px-4 py-2 text-center'>{contributionType.description}</td>
                    <td className='border-2 px-4 py-2 text-center'>
                      <button
                        className="px-4 py-2 m-1 bg-red-500 hover:bg-red-700 text-white rounded-md shadow-md  text-center"
                        onClick={() => handleDelete(contributionType.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {addType && <ContributionTypeForm setContributionType={setAddType} />}
        </>
      }
    </div>
  );
};

export default ContributionTypeTable;
