import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="bg-gray-900 text-white py-4">
            <div className="container mx-auto flex justify-center">
                <p className="text-sm">
                    &copy; {new Date().getFullYear()} <Link className='text-blue-400' target='_blank'
                        to={`https://www.burundientempsreel.com/`}>Burundi en Temps Réel</Link>. All rights reserved.
                </p>
            </div>
        </footer>
    );
};

export default Footer;