import React from 'react';
import { Link } from 'react-router-dom';

export default function ErrorPage() {
  return (
    <div className="max-w-screen-xl mx-auto h-full p-4 m-3 rounded-md shadow-md bg-slate-100">
      <div className="flex flex-col justify-center items-center bg-white p-4 rounded shadow-2xl">
        <h1 className="text-[25px] font-bold">404! Not found 😰</h1>
        <p className="text-red-500 text-lg">The page that you are looking is not available or has been removed.</p>
        <Link to="/" className="text-white mt-4 bg-orange-300 p-2 px-4 rounded hover:bg-orange-500">Go back to Home</Link>
      </div>
    </div>
  );
}