import React, { useEffect, useState } from 'react';
import axios from 'axios';

import SpinnerOverlay from '../Other/Spinner';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AddLoanForm = (props) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        amount: '',
        date_taken: '',
        date_due: '',
        interest_rate: '',
        description: '',
    });

    const handlePictureChange = (e) => {
        props.setProofImage(e.target.files[0]);
    };

    const [member, setMember] = useState({});
    const userId = localStorage.getItem('contrUserId');
    const groupId = localStorage.getItem('contGroupId');

    useEffect(() => {
        // Fetch data from the endpoint
        axios.get(`https://django.burundientempsreel.com/btr/cotisations/member/details/${props.memberId}/`)
            .then(response => {
                setMember(response.data);
            })
            .catch(error => {
                console.error('Error fetching contribution types:', error);
            });
    }, [props.memberId]);

    const [sending, setSending] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.amount.trim() === "") {
            toast.warning("The loan amount is required!", { position: "top-right", autoClose: 3000 });
            return;
        }
        if (formData.date_taken.trim() === "") {
            toast.warning("The date that you have given the loan is required!", { position: "top-right", autoClose: 4000 });
            return;
        }
        if (formData.interest_rate.trim() === "") {
            toast.warning("The interest rate is required!", { position: "top-right", autoClose: 3000 });
            return;
        }

        let data = {
            ...formData,
            "borrower": parseInt(props.memberId),
            "group": parseInt(groupId),
            "user": parseInt(userId),
        }

        if (props.proofImage) {
            data = {
                ...data,
                "proof_image": props.proofImage,
            }
        }
        // console.log("Add loan data: ", data);

        setSending(true);
        // setTimeout(() => {
        //     setSending(false);
        // }, 3000);

        try {
            const response = await axios.post('https://django.burundientempsreel.com/btr/cotisations/loans/create/', data, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            if (response.data.success) {
                toast.success(`You have successfully given loan to the member`, { autoClose: 3000, position: "top-right" });
                props.setGiveLoan(false);
                navigate('/view-all/loans/');
            }
        } catch (error) {
            console.log("Add loan error: ", error);
        } finally {
            setSending(false);
        }
    };

    const closeAddLoanForm = () => {
        props.setGiveLoan(false);
    }

    return (
        <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-50">
            <div className='max-w-screen-sm w-full p-4 m-3 rounded-md shadow-md bg-gray-200'>
                <div className='flex justify-between items-center mb-3'>
                    <h2 className='font-bold text-xl'>Give a Loan to <span className="text-indigo-700">{member.Nom + " " + member.Prenom}</span></h2>
                    <button onClick={closeAddLoanForm} className='bg-blue-800 p-1 rounded-full w-10 h-10 cursor-pointer'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" /></svg>
                    </button>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="mb-2">
                        <label htmlFor="amount" className="block text-gray-700">Amount</label>
                        <input type="number" id="amount" name="amount" value={formData.amount} onChange={handleChange}
                            className='rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500 w-full' />
                    </div>
                    <div className="mb-2">
                        <label htmlFor="dateTaken" className="block text-gray-700">Date Taken</label>
                        <input type="date" id="dateTaken" name="date_taken" value={formData.date_taken} onChange={handleChange}
                            className='rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500 w-full' />
                    </div>
                    <div className="mb-2">
                        <label htmlFor="dateDue" className="block text-gray-700">Date Due</label>
                        <input type="date" id="dateDue" name="date_due" value={formData.date_due} onChange={handleChange}
                            className='rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500 w-full' />
                    </div>
                    <div className="mb-2">
                        <label htmlFor="interestRate" className="block text-gray-700">Interest Rate</label>
                        <input type="number" id="interestRate" placeholder='0.1' name="interest_rate" value={formData.interest_rate} onChange={handleChange}
                            className='rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500 w-full' />
                    </div>
                    <div className="mb-2">
                        <label htmlFor="description" className="block text-gray-700">Description:</label>
                        <textarea id="description" name="description" value={formData.description}
                            onChange={handleChange}
                            className='rounded-md border w-full border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500'></textarea>
                    </div>

                    <div className="flex flex-col mb-2">
                        <h1 className="text-sm font-medium mb-2">
                            Proof image (optional):
                        </h1>
                        <label htmlFor="profile_picture" className="cursor-pointer text-center text-sm font-medium mb-2 rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-orange-500 hover:bg-orange-700">
                            Attach the proof image
                        </label>
                        <input
                            type="file" accept="image/*"
                            id="profile_picture"
                            onChange={handlePictureChange}
                            className="hidden"
                        />
                        {props.proofImage && <img src={URL.createObjectURL(props.proofImage)} className="w-36 h-36 border border-gray-300 rounded-md" alt="Proof" />}
                    </div>

                    <div className="flex justify-end items-center self-end col-span-2">
                        <div className="col-span-full flex justify-self-end">
                            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">Give Loan</button>
                        </div>
                    </div>
                </form>
            </div>
            <SpinnerOverlay isLoading={sending} />
        </div>
    );
};

export default AddLoanForm;
