import React, { useState } from 'react';
import { toast } from 'react-toastify';
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { Link, useParams, useNavigate } from 'react-router-dom';

const MemberForm = () => {

  const navigate = useNavigate();

  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);

  const userId = localStorage.getItem('contrUserId');
  const groupId = localStorage.getItem('contGroupId');

  const [sending, setSending] = useState(false);

  const handlePictureChange = (e) => {
    setProfilePicture(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let formData = {
      'Nom': nom,
      'Prenom': prenom,
      'email': email,
      'phone_number': phoneNumber,
      'address': address,
      "group": parseInt(groupId),
      "user": parseInt(userId),
    }
    if (profilePicture) {
      formData = {
        ...formData,
        'profile_picture': profilePicture,
      }
    }

    console.log("FormData: ", formData);

    setSending(true);

    await axios.post('https://django.burundientempsreel.com/btr/cotisations/member/create/', formData, {
      headers: { "Content-Type": "multipart/form-data" }
    }).then((response) => {
      if (response.data.success) {
        toast.success('Member created successfully!');
        navigate("/members/view/all/");

        // Clear form after successful submission (optional)
        setNom(''); setPrenom(''); setEmail('');
        setPhoneNumber(''); setAddress(''); setProfilePicture(null);

      } else if (response.data.error) {
        toast.warning(`${response.data.message}`);
      }
    }).catch((error) => {
      console.error(error);
      toast.error('An error occurred. Please try again.');
    }).finally(() => { setSending(false); })
  };

  return (
    <div className='max-w-screen-md mx-auto p-4 m-3 rounded-md shadow-md'>
      <div className='flex justify-center items-center mb-3'>
        <h2 className='font-bold text-2xl text-center'>Register a new member here</h2>
      </div>
      <div className='border border-orange-700 rounded-md p-4'>
        <form onSubmit={handleSubmit} className="">
          <div className="flex flex-col">
            <label htmlFor="nom" className="text-sm font-medium mb-2">
              Member First name:
            </label>
            <input
              type="text"
              id="nom"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              className="rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500"
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="prenom" className="text-sm font-medium mb-2">
              Member Last name:
            </label>
            <input
              type="text"
              id="prenom"
              value={prenom}
              onChange={(e) => setPrenom(e.target.value)}
              className="rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500"
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="email" className="text-sm font-medium mb-2">
              Member Email address:
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500"
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="phone_number" className="text-sm font-medium mb-2">
              Phone Number:
            </label>
            <input
              type="tel"
              id="phone_number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500"
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="address" className="text-sm font-medium mb-2">
              Physical Address:
            </label>
            <textarea
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500"
              required
            />
          </div>
          <div className="flex flex-col">
            <h1 className="text-sm font-medium mb-2">
              Profile Picture (optional):
            </h1>
            <label htmlFor="profile_picture" className="cursor-pointer text-center text-sm font-medium mb-2 rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-orange-500 hover:bg-orange-700">
              Select the Profile Picture
            </label>
            <input
              type="file" accept="image/*"
              id="profile_picture"
              onChange={handlePictureChange}
              className="hidden"
            />
            {profilePicture && <img src={URL.createObjectURL(profilePicture)} className="h-42 border border-gray-300 rounded-md" />}
          </div>

          <div className="flex justify-end items-center self-end col-span-2">
            <div className="col-span-full flex justify-self-end">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center justify-center"
              >
                <span className="mr-2">Add Member</span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <SpinnerOverlay isLoading={sending} />
    </div>
  );
};

export default MemberForm;


const SpinnerOverlay = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <div className="fixed top-0 left-0 z-50 w-full h-full flex flex-col items-center justify-center bg-slate-300 bg-opacity-10">
          <div>
            <div className="flex justify-center items-center space-x-2">
              <span className="text-blue-900 text-2xl font-serif animate-bounce">Adding member</span>
              <div className="w-3 h-3 bg-blue-900 rounded-full animate-bounce"></div>
              <div className="w-3 h-3 bg-blue-900 rounded-full animate-bounce"></div>
              <div className="w-3 h-3 bg-blue-900 rounded-full animate-bounce"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
