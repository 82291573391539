import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from "axios";

const ContributionGroupLoginForm = (props) => {

  const userId = localStorage.getItem('contrUserId');

  const [name, setName] = useState('');
  const [codeSecurite, setCodeSecurite] = useState('');

  const [sending, setSending] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name.trim() === "") {
      toast.warning("The name of the group is required!");
      return;
    }

    if (codeSecurite.trim() === "") {
      toast.warning("The Security code of the group is required!");
      return;
    }

    const formData = {
      "name": name,
      "code": codeSecurite,
      "user": userId
    };

    setSending(true);

    await axios.post('https://django.burundientempsreel.com/btr/cotisations/filter-contribution-group/',
      formData,
    ).then((response) => {
      // console.log("Res data: ", response.data);
      if (response.data.success) {
        toast.success(`${response.data.detail}`, { autoClose: 3000, position: "top-right" });

        localStorage.setItem("contGroupId", response.data.data.id);
        console.log("Group id saved locally successfully");

        props.setRegister(false);
        props.setLogin(false);

        setName(''); setCodeSecurite('');
      }
      else if (response.data.notFound) {
        toast.error(`The credentials used do not match any Contribution Group. Please try again!`);
      }
      else if (response.data.error) {
        toast.error(`${response.data.message}`);
      }
    }).catch((err) => {
      if (err.message == "Request failed with status code 404") {
        toast.warning("The Credentials are not found on the server")
      }
      console.log("Error msg: ", err.message);
      console.error(err);
    }).finally(() => {
      setSending(false);
    })
  };

  const handleRegisterLink = () => {
    props.setLogin(false);
    props.setRegister(true);
  }

  const handleLogin = () => {
    props.setLogin(false);
  }

  return (
    <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-80">
      <div className='max-w-screen-sm w-full p-4 m-3 rounded-md shadow-md bg-gray-200'>
        <div className='flex justify-between items-center mb-3'>
          <h2 className='font-bold text-2xl text-orange-800'>Add Contribution Group Credentials</h2>
          <button onClick={handleLogin} className='bg-blue-800 p-1 rounded-full w-10 h-10 cursor-pointer'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" /></svg>
          </button>
        </div>

        <div className='border border-orange-700 rounded-md p-4'>
          <form onSubmit={handleSubmit} className='space-y-4'>
            <div className='flex flex-col'>
              <label htmlFor='name' className='text-sm font-medium mb-2'>
                Name of the Contribution Group:
              </label>
              <input
                type='text'
                id='name'
                value={name}
                onChange={(e) => setName(e.target.value)}
                className='rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500'
              />
            </div>

            <div className='flex flex-col'>
              <label
                htmlFor='code_securite'
                className='text-sm font-medium mb-2'
              >
                Security Code:
              </label>
              <input
                type='password'
                id='code_securite'
                value={codeSecurite}
                onChange={(e) => setCodeSecurite(e.target.value)}
                className='rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500'
              />
            </div>

            <div className="flex justify-end items-center self-end">
              <div className="col-span-full flex justify-self-end">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center justify-center"
                >
                  <span className="mr-2">Login the contribution group</span>
                </button>
              </div>
            </div>
            <div className='flex justify-end items-center mb-3'>
              <p className='text-sm flex flex-row'>
                <span className="m-1">No Group?</span>
                <button onClick={handleRegisterLink} className="text-blue-600 m-1 hover:text-blue-800">Create a contribution group</button>
              </p>
            </div>
          </form>
        </div>
        <SpinnerOverlay isLoading={sending} />
      </div>
    </div>
  );
};

export default ContributionGroupLoginForm;


const SpinnerOverlay = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <div className="fixed top-0 left-0 z-50 w-full h-full flex flex-col items-center justify-center bg-slate-300 bg-opacity-10">
          <div>
            <div className="flex justify-center items-center space-x-2">
              <span className="text-blue-900 text-2xl font-serif animate-bounce">Checking contribution group</span>
              <div className="w-3 h-3 bg-blue-900 rounded-full animate-bounce"></div>
              <div className="w-3 h-3 bg-blue-900 rounded-full animate-bounce"></div>
              <div className="w-3 h-3 bg-blue-900 rounded-full animate-bounce"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

