import React from 'react';

const EmptyPage = ({ text }) => {
    return (
        <div className="flex items-center justify-center h-screen">
            <div className="text-gray-500 text-lg">
                No content available for {text}.
            </div>
        </div>
    );
};

export default EmptyPage;