import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const UserProfile = ({ user }) => {
    return (
        <div className="flex flex-col justify-start items-start w-fit border bg-emerald-100 rounded-md shadow-md p-2 my-2">
            <h2 className="text-2xl mb-2 font-serif">User who registered the member</h2>
            <p>Full name: <span className='font-bold text-slate-600'>{user.first_name + ' ' + user.last_name}</span></p>
            <p>Username: <span className='font-bold text-slate-600'>{user.username}</span></p>
            <p>Email: <span className='font-bold text-slate-600'>{user.email}</span></p>
        </div>
    );
};

const GroupDetails = ({ group }) => {
    return (
        <div className="flex flex-col justify-start items-start w-fit border bg-gray-300 rounded-md shadow-md p-2 my-2">
            <h2 className="text-2xl mb-2 font-serif">Group Details</h2>
            <p>Name: <span className='font-bold text-slate-500'>{group.name}</span></p>
            <p>Code: <span className='font-bold text-slate-500'>{group.code}</span></p>
            <p>Slogan: <span className='font-bold text-slate-500'>{group.slogan}</span></p>
        </div>
    );
};

const Member = ({ member }) => {
    return (
        <div className='flex flex-col justify-center items-center w-full border bg-sky-200 rounded-md shadow-md p-2 my-2'>
            <h2 className="text-2xl mb-2 font-serif border border-cyan-700 p-2 rounded-md">Member Details</h2>

            <div className="flex flex-wrap justify-center items-center w-full border">
                <div className='w-48 h-48 flex justify-center items-center rounded-full m-2'>
                    <img src={`${member.profile_picture}`} alt={member.Nom} className='w-full h-full rounded-full object-cover' />
                </div>
                <div className="flex flex-col justify-start items-start rounded-md bg-slate-300 shadow-md p-4 m-2">
                    <p>Nom: <span className='font-bold text-gray-800'>{member.Nom}</span></p>
                    <p>Prénom: <span className='font-bold text-gray-800'>{member.Prenom}</span></p>
                    <p>Email: <span className='font-bold text-gray-800'>{member.email}</span></p>
                    <p>Phone Number: <span className='font-bold text-gray-800'>{member.phone_number}</span></p>
                    <p>Address: <span className='font-bold text-gray-800'>{member.address}</span></p>
                </div>
            </div>
        </div>
    );
};

const MemberDetails = () => {
    const navigate = useNavigate();
    const { memberId } = useParams();

    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});
    const [group, setGroup] = useState({});
    const [member, setMember] = useState({});
    const [memberData, setMemberData] = useState({});

    useEffect(() => {
        async function getMembers() {
            axios.get(`https://django.burundientempsreel.com/btr/cotisations/member/details/${memberId}`)
                .then(response => {
                    // console.log('Response:', response.data);
                    if (response.data) {

                        setMemberData(response.data)

                        setUser(response.data.user);
                        setGroup(response.data.group);

                        const memberData = {
                            Nom: response.data.Nom,
                            Prenom: response.data.Prenom,
                            email: response.data.email,
                            phone_number: response.data.phone_number,
                            address: response.data.address,
                            profile_picture: response.data.profile_picture,
                            created: response.data.created,
                        };

                        setMember(memberData);
                    }
                    else {
                        setMemberData({})
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                }).finally(() => { setLoading(false); })
        }

        getMembers();
    }, [memberId])

    const handleDeleteMember = (memberId) => {
        Swal.fire({
            title: "Do you want to delete the member? If you are not sure, you may deactivate it!",
            showCancelButton: true,
            confirmButtonText: "Delete",
            icon: "warning",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.delete(`https://django.burundientempsreel.com/btr/cotisations/member/${memberId}/delete/`)
                    .then(response => {
                        // Handle successful deletion
                        // console.log("Del res: ", response.data);
                        toast.success('Member deleted successfully', { autoClose: 3000, position: "top-right" });
                        navigate('/members/view/all/');
                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error deleting member:', error);
                        // Display an error message or perform any additional actions
                    });
            } else {
                console.log("Deletion canceled!");
            }
        });
    };

    const handleActivateMember = (contrId) => {
        console.log("Activating member id: ", contrId);
        Swal.fire({
            title: "Do you want to activate this member?",
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.patch(`https://django.burundientempsreel.com/btr/members/${contrId}/status/`, {
                    "is_activated": true
                })
                    .then(response => {
                        // console.log("Del res: ", response.data);
                        toast.success('Member activated successfully.', { autoClose: 3000, position: "top-right" });
                        navigate('/members/view/all/');
                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error activating member:', error);
                        // Display an error message or perform any additional actions
                    });
            } else {
                console.log("Deletion canceled!");
            }
        });
    }

    const handleNotDeactivateMember = (contrId) => {
        console.log("Deactivating contribution id: ", contrId);
        Swal.fire({
            title: "Do you want to deactivate this member?!",
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.patch(`https://django.burundientempsreel.com/btr/members/${contrId}/status/`, {
                    "is_activated": false
                })
                    .then(response => {
                        // console.log("Del res: ", response.data);
                        toast.success('Member deactivated successfully.', { autoClose: 3000, position: "top-right" });
                        navigate('/members/view/all/');
                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error deactivating member:', error);
                        // Display an error message or perform any additional actions
                    });
            } else {
                console.log("Deactivating canceled!");
            }
        });
    }

    return (
        <>
            {loading ? (
                <div className="flex flex-col min-h-screen justify-center items-center">
                    <ClipLoader size={50} color="gray" />
                    <h1>Loading...</h1>
                </div>
            ) : (
                <div className='max-w-screen-xl mx-auto p-4 m-3 rounded-md shadow-md bg-slate-100'>

                    <div className='flex justify-start items-center mb-3'>
                        <Link to={`/members/view/all/`}
                            className="px-4 py-2 bg-blue-500 text-white rounded-md shadow-md text-center"
                        >
                            Return
                        </Link>
                    </div>
                    <div className='flex justify-center items-center mb-3'>
                        <h2 className='font-bold text-2xl text-center flex-wrap'>All registered Members</h2>
                    </div>

                    <div className='flex justify-between items-center flex-wrap w-full'>
                        <UserProfile user={user} />
                        <GroupDetails group={group} />
                    </div>
                    <Member member={member} />

                    <div className="flex flex-wrap justify-end mt-4">
                        <button
                            className="px-4 py-2 m-1 bg-green-500 hover:bg-green-700 text-white rounded-md shadow-md text-center"
                            onClick={() => handleActivateMember(memberData.id)}
                        >
                            Activate
                        </button>
                        <button
                            className="px-4 py-2 m-1 bg-orange-600 hover:bg-orange-700 text-white rounded-md shadow-md  text-center"
                            onClick={() => handleNotDeactivateMember(memberId)}
                        >
                            Deactivate
                        </button>
                        <button
                            className="px-4 py-2 m-1 bg-red-500 hover:bg-red-700 text-white rounded-md shadow-md  text-center"
                            onClick={() => handleDeleteMember(memberId)}
                        >
                            Delete
                        </button>
                        <Link unselectable='off' to={`/contribution/${memberId}/edit`}
                            className="px-4 py-2 m-1 bg-blue-500 hover:bg-blue-700 text-white rounded-md shadow-md text-center"
                        >
                            Edit Member
                        </Link>
                    </div>
                </div>
            )
            }
        </>
    );
};

export default MemberDetails;