import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';

import logo from '../../favicon.png'
import SpinnerOverlay from '../Other/Loader';
import { toast } from 'react-toastify';

const UserInput = ({ label, name, type, placeholder, value, onChange }) => {
    return (
        <div className="mb-2 sm:col-span-2">
            <label htmlFor={name} className="block text-gray-700 text-sm font-bold mb-2">
                {label}
            </label>
            <input
                type={type}
                id={name}
                name={name}
                placeholder={placeholder}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={value}
                onChange={onChange}
            />
        </div>
    );
};


const UserForm = () => {

    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [date_of_birth, setDateOfBirth] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [profilePicture, setProfilePicture] = useState(null);

    const handlePictureChange = (event) => {
        setProfilePicture(event.target.files[0]);
    };

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (firstName.trim() === "") {
            toast.warning("Please provide your First name", {
                autoClose: 3000, position: "top-right"
            })
            return;
        }

        if (lastName.trim() === "") {
            toast.warning("Please provide your Last name", {
                autoClose: 3000, position: "top-right"
            })
            return;
        }

        if (email.trim() === "") {
            toast.warning("Please enter the email address", {
                autoClose: 3000, position: "top-right"
            })
            return;
        }

        if (username.trim() === "") {
            toast.warning("Please provide the username that you will use!", {
                autoClose: 3000, position: "top-right"
            })
            return;
        }

        if (phoneNumber.trim() === "") {
            toast.warning("Please provide your phone number!", {
                autoClose: 3000, position: "top-right"
            })
            return;
        }

        if (password.trim() === "") {
            toast.warning("Please provide the password that you will use!", {
                autoClose: 3000, position: "top-right"
            })
            return;
        }

        if (password.trim() !== confPassword.trim()) {
            toast.warning("Passwords do not match", {
                autoClose: 3000, position: "top-right"
            })
            return;
        }

        let formData = {
            first_name: firstName, last_name: lastName, username: username, email: email,
            password: password, phone_number: phoneNumber
        };

        if (profilePicture) {
            formData = { ...formData, profile_picture: profilePicture }
        }

        if (date_of_birth) {
            formData = { ...formData, date_of_birth: date_of_birth }
        }

        console.log('Submitting form data:', formData);

        await axios.post('https://django.burundientempsreel.com/btr/cotisations/users/create/', formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(response => {
            console.log("Register data: ", response.data);
            if (response.data.success) {
                toast.success("Your account has been registered successfully", {
                    autoClose: 3000,
                    position: "top-right",
                })
                navigate('/');
            }
            if (response.data.error) {
                if (response.data.message.username[0]) {
                    toast.error(response.data.message.username[0], {
                        autoClose: 3000,
                        position: "top-right",
                    })
                }
            }
        }).catch(err => {
            console.log("Error registering account ", err)
        }).finally(() => { setIsLoading(false); });
    };


    return (
        <div className='max-w-screen-md mx-auto p-2 m-3'>
            <div className='flex justify-center items-center mb-3'>
                <h2 className='font-bold text-2xl'>Registration Form</h2>
            </div>
            <div className='border border-orange-700 rounded-md flex flex-col justify-center p-2'>
                <div className="flex self-center max-w-60 max-h-60 mb-4">
                    <img src={`${logo}`} alt='Logo' className='rounded-md w-full h-full object-cover ' />
                </div>
                <form onSubmit={handleSubmit} className="grid-cols-1 md:grid-cols-2 gap-4">
                    <UserInput
                        label="First Name"
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => { setFirstName(e.target.value); }}
                    />
                    <UserInput
                        label="Last Name"
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => { setLastName(e.target.value); }}
                    />
                    <UserInput
                        label="Email"
                        name="email"
                        type="email"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => { setEmail(e.target.value); }}
                    />
                    <UserInput
                        label="Phone Number"
                        name="phoneNumber"
                        type="tel"
                        placeholder="123-456-7890"
                        value={phoneNumber}
                        onChange={(e) => { setPhoneNumber(e.target.value); }}
                    />
                    <UserInput
                        label="Username"
                        name="username"
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => { setUsername(e.target.value); }}
                    />
                    <UserInput
                        label="Password"
                        name="password"
                        type="password"
                        placeholder="**********"
                        value={password}
                        onChange={(e) => { setPassword(e.target.value); }}
                    />
                    <UserInput
                        label="Confirm Password"
                        name="confPassword"
                        type="password"
                        placeholder="**********"
                        value={confPassword}
                        onChange={(e) => { setConfPassword(e.target.value); }}
                    />
                    <UserInput
                        label="Date of Birth"
                        name="dateOfBirth"
                        type="date"
                        value={date_of_birth}
                        onChange={(e) => { setDateOfBirth(e.target.value); }}
                    />
                    <div className="mb-4">
                        <label htmlFor="profilePicture" className="block text-white text-sm hover:bg-orange-600 bg-orange-800 rounded w-full p-2 cursor-pointer font-serif mb-2">
                            Select your profile picture
                        </label>
                        <input accept='image/*'
                            type="file"
                            id="profilePicture"
                            name="profilePicture"
                            className="hidden shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            onChange={handlePictureChange}
                        />
                        {profilePicture && <img className='w-40 h-auto rounded-md' src={URL.createObjectURL(profilePicture)} alt='Profile picture' />}
                    </div>

                    <div className='flex self-end justify-end items-center'>
                        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                            Sign up
                        </button>
                    </div>
                    <div className='flex self-end justify-end items-center mb-3'>
                        <p className='text-sm flex flex-row'>
                            <span className="m-1">Have an account?</span>
                            <Link to="/" className="text-blue-600 m-1 hover:text-blue-800">Signin here</Link>
                        </p>
                    </div>
                </form>
            </div>
            <SpinnerOverlay isLoading={isLoading} />
        </div>
    );
};

export default UserForm;
