import React, { useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";

import SpinnerOverlay from '../Other/Spinner';


const ContributionTypeForm = (props) => {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  const groupId = localStorage.getItem("contGroupId");
  const userId = localStorage.getItem('contrUserId');

  const [sending, setSending] = useState(false);

  // Options for select dropdown
  const options = [
    { value: 'MONTHLY', label: 'Monthly' },
    { value: 'QUARTERLY', label: 'Quarterly' },
    { value: 'SEMESTER', label: 'Semester' },
    { value: 'ANNUAL', label: 'Annual' },
  ];

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setName(selectedOption.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name.trim() === "") {
      toast.warning("The name of the contribution type is required!");
      return;
    }
    if (description.trim() === "") {
      toast.warning("The description of the contribution type is required!");
      return;
    }

    if (!groupId) {
      toast.warning("Return to home to log in a contribution group!");
      return;
    }

    if (!userId) {
      toast.warning("You are not connected! Please log in to add a contribution type!");
      return;
    }

    let formData = {
      "name": name, "description": description,
      "group": groupId, "user": userId
    };

    setSending(true);

    await axios.post('https://django.burundientempsreel.com/btr/cotisations/contribution-types/create/',
      formData,
    ).then((response) => {
      console.log(response.data);
      if (response.data) {
        toast.success('Contribution type created successfully!');

        // Clear form after successful submission (optional)
        setName('');
        setDescription('');
        setSelectedOption(null);
        props.setContributionType(false);
      } else if (response.data.error) {
        toast.warning(`${response.data.error}`);
      }
    }).catch((err) => { console.log("Error: ", err); })
      .finally(() => {
        setSending(false);
      });
  };

  const closeContributionTypeForm = () => {
    props.setContributionType(false);
  }

  return (
    <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-80">
      <div className='max-w-screen-sm w-full p-4 m-3 rounded-md shadow-md bg-gray-200'>
        <div className='flex justify-between items-center mb-3'>
          <h2 className='font-bold text-2xl'>Create a new Contribution Type</h2>
          <button onClick={closeContributionTypeForm} className='bg-blue-800 p-1 rounded-full w-10 h-10 cursor-pointer'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" /></svg>
          </button>
        </div>

        {/* <div className='flex justify-center items-center mb-3'>
          <p className='font-semibold text-inherit text-lg'>We have detected that there are not registered contrbution types such as <span className='text-red-700'>MONTHLY, QUARTERLY(3 months in a row), SEMESTER(6 months in a row), or ANNUAL.</span> You first have to create it and continue.</p>
        </div> */}

        <div className='border border-orange-700 rounded-md p-4'>
          <form onSubmit={handleSubmit} className="flex flex-col">
            <div className='mb-4'>
              <label htmlFor='contribution-type' className='block text-sm font-medium mb-2'>
                Select Contribution Type:
              </label>
              <Select
                id='contribution-type'
                value={selectedOption}
                onChange={handleSelectChange}
                options={options}
                className="w-full"
              />
            </div>
            <div className='mb-4'>
              <label htmlFor='description' className='block text-sm font-medium mb-2'>
                Description:
              </label>
              <textarea
                id='description'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className='rounded-md border border-gray-300 p-2 w-full focus:outline-none focus:ring-1 focus:ring-blue-500'
              />
            </div>

            <div className="flex justify-self-end items-center self-end col-span-2">
              <div className="col-span-full flex justify-self-end">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center justify-center"
                >
                  <span className="mr-2">Create Contribution Type</span>
                </button>
              </div>
            </div>
          </form>
        </div>
        <SpinnerOverlay isLoading={sending} />
      </div>

    </div>
  );
};

export default ContributionTypeForm;
