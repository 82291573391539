import React from "react";

const SpinnerOverlay = ({ isLoading }) => {
    return (
        <>
            {isLoading && (
                <div className="fixed top-0 left-0 z-50 w-full h-full flex flex-col items-center justify-center bg-slate-300 bg-opacity-10">
                    <div>
                        <div className="flex justify-center items-center space-x-2">
                            <span className="text-blue-900 text-2xl font-serif animate-bounce">Please wait</span>
                            <div className="w-3 h-3 bg-blue-900 rounded-full animate-bounce"></div>
                            <div className="w-3 h-3 bg-blue-900 rounded-full animate-bounce"></div>
                            <div className="w-3 h-3 bg-blue-900 rounded-full animate-bounce"></div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SpinnerOverlay;
