import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { Link, useNavigate } from 'react-router-dom';

import ContributionForm from '../Cotisations/ContributionForm';
import AddLoanForm from '../Loans/AddLoan';

const MemberTable = () => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);

  const [contribute, setContribute] = useState(false);
  const [giveLoan, setGiveLoan] = useState(false);

  const userId = localStorage.getItem('contrUserId');
  const groupId = localStorage.getItem('contGroupId');

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);

  const [loanId, setLoanId] = useState(null);
  const [contributeId, setContributeId] = useState(null);

  const handleContribute = (id) => {
    setContribute(true);
    setContributeId(id);
  }

  const handleGiveLoan = (id) => {
    setGiveLoan(true);
    setLoanId(id);
  }

  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const activationOptions = [
    { value: 'True', label: 'Activated' },
    { value: 'False', label: 'Deactivated' },
  ];
  const [selectedActivationOption, setSelectedActivationOption] = useState('');

  const filteredMembers = members.filter((member) => {
    if (searchTerm.trim() === '') {
      return true;
    }

    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const lowerCaseActivatedId = member.id.toString().toLowerCase();
    // const lowerCaseProfile = member.profile_picture;
    const lowerCaseFullname = member.Nom.toLowerCase() + member.Prenom.toLowerCase();
    const lowerCasePhonenumber = member.phone_number.toString().toLowerCase();
    const lowerCaseActivated = member.is_activated ? "Activated" : "Deactivated";

    return (
      lowerCaseActivatedId.includes(lowerCaseSearchTerm) ||
      // lowerCaseProfile ||
      lowerCaseFullname.includes(lowerCaseSearchTerm) ||
      lowerCasePhonenumber.includes(lowerCaseSearchTerm) ||
      lowerCaseActivated.toLowerCase().includes(lowerCaseSearchTerm)
    );
  });

  const handleSelectActivationOptionChange = (e) => {
    setSelectedActivationOption(e.target.value);
  };

  const nbOptions = [
    { label: "1", value: 1 },
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
  ]

  const handleSelectPageNumberChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
  };

  useEffect(() => {
    const fetchMembers = async () => {
      const uniqueParam = new Date().getTime();
      try {
        let params = {};
        if (selectedActivationOption) {
          // If only activated selected, fetch with is_activated parameter
          params = { is_activated: selectedActivationOption };
        }
        else if (!selectedActivationOption || itemsPerPage || currentPage) {
          // Fetch all expenses for the group ID
          const allMembersResponse = await axios.get(`https://django.burundientempsreel.com/btr/cotisations/members/group/${groupId}?page_size=${itemsPerPage}&page=${currentPage}&cacheBuster=${uniqueParam}`);

          setTotalEntries(Math.ceil(allMembersResponse.data.count));
          if (allMembersResponse.data.count > 0) {
            setMembers(allMembersResponse.data.results);
            setTotalPages(Math.ceil(allMembersResponse.data.count / itemsPerPage));
          }
          return;
        }

        // Fetch expenses based on selected options
        const filteredMembersResponse = await axios.get('https://django.burundientempsreel.com/api/members/filter/', { params });
        setMembers(filteredMembersResponse.data);

      } catch (error) {
        console.error('Error fetching expenses:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMembers();
  }, [selectedActivationOption, groupId, currentPage, itemsPerPage, contribute, giveLoan]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const goToPreviousPage = (page) => {
    if (page > 1) {
      setCurrentPage(page - 1);
    }
  };
  const goToNextPage = (page) => {
    if (page < totalPages) {
      setCurrentPage(page + 1);
    }
  };

  const [proofImage, setProofImage] = useState(null);

  return (
    <>
      {loading ? (
        <div className="flex flex-col min-h-screen justify-center items-center">
          <ClipLoader size={50} color="gray" />
          <h1>Loading...</h1>
        </div>
      ) : (
        <div className='max-w-screen-xl mx-auto p-4 '>
          <h2 className='font-bold text-2xl text-center flex-wrap'>All registered Members</h2>

          <div className='overflow-x-auto w-full p-2 rounded-md shadow-md bg-slate-100'>

            <div className='flex flex-wrap justify-between items-center mb-3 w-full'>
              <div className="flex justify-center items-center flex-wrap mb-4">
                <div className='flex flex-col justify-center items-start m-1 p-1'>
                  <label htmlFor="searchText" className='text-lg text-gray-800'>Filter members</label>
                  <input type="text" id='searchText'
                    placeholder="Filter members..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className="border border-gray-400 px-2 py-1 w-full rounded-md"
                  />
                </div>
                <div className='flex flex-col justify-center items-start m-1 p-1'>
                  <label htmlFor="selectedActivationOption" className='text-lg text-gray-800'>Members by return</label>
                  <select id="selectedActivationOption" value={selectedActivationOption} className='w-full border border-gray-400 rounded-md px-2 py-1'
                    onChange={handleSelectActivationOptionChange}>
                    <option value="">Select...</option>
                    {activationOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='flex flex-col justify-center items-start m-1 p-1'>
                  <label htmlFor="selectedPageNumb" className='text-sm text-gray-800'>Number per page</label>
                  <select id="selectedPageNumb" value={itemsPerPage} className='w-full border border-gray-400 rounded-md px-2 py-1'
                    onChange={handleSelectPageNumberChange}>
                    <option value="">Number...</option>
                    {nbOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <Link to="/members/create/" className="text-white bg-blue-600 mx-2 my-2 p-2 px-4 rounded hover:bg-blue-800">Add a new member</Link>
            </div>

            <table className='table-auto w-full border rounded-md'>
              <thead>
                <tr>
                  <th className='border-2 px-4 py-2'>Profile Picture</th>
                  <th className='border-2 px-4 py-2'>Full name</th>
                  <th className='border-2 px-4 py-2'>Phone Number</th>
                  <th className='border-2 px-4 py-2'>Activated</th>
                  <th className='border-2 px-4 py-2'>Actions</th>
                </tr>
                {filteredMembers.length === 0 && (
                  <tr>
                    <th
                      className='border-2 px-4 py-2 col-span-5 bg-red-800 text-white'
                      colSpan='6'
                    >
                      No members added for now
                    </th>
                  </tr>
                )}
              </thead>
              <tbody>
                {filteredMembers.map((member) => (
                  <tr key={member.id}>
                    <td className='border-2 px-2 py-1'>
                      {member.profile_picture && (
                        <div className='flex items-center justify-center'>
                          <img
                            src={`${member.profile_picture}`}
                            alt='Profile'
                            className='w-12 h-12 rounded-full'
                          />
                        </div>
                      )}
                    </td>
                    <td className='border-2 px-2 py-1 text-center'>{member.Nom + " " + member.Prenom}</td>
                    <td className='border-2 px-2 py-1 text-center'>{member.phone_number}</td>
                    <td className={`border-2 px-4 py-2 text-white text-center ${member.is_activated ? "bg-green-700" : "bg-red-700"}`}>{member.is_activated ? "Activated" : "Deactivated"}</td>
                    <td className='border-2 px-2 py-1 text-center'>
                      <div className=" flex justify-center items-center">
                        <div className="flex justify-center items-center flex-wrap">
                          <button onClick={() => handleContribute(member.id)} className="m-1 text-white bg-orange-600 p-1 rounded hover:bg-orange-800">Contribute</button>
                          <button onClick={() => handleGiveLoan(member.id)} className="m-1 text-white bg-green-600 p-1 rounded hover:bg-green-800">Give loan</button>
                        </div>

                        <div className="flex flex-col justify-center items-center m-1">
                          <Link to={`/member/details/${member.id}`} className="m-1 text-white bg-blue-600 hover:bg-blue-800 duration-300 p-1 rounded flex flex-col justify-between items-center">
                            <span>Details</span>
                          </Link>
                        </div>
                      </div>
                    </td>

                    {contribute && <ContributionForm setContribute={setContribute} memberId={contributeId} proofImage={proofImage} setProofImage={setProofImage} />}
                    {giveLoan && <AddLoanForm setGiveLoan={setGiveLoan} memberId={loanId} proofImage={proofImage} setProofImage={setProofImage} />}

                  </tr>
                ))}
              </tbody>
            </table>

            {filteredMembers.length > 0 &&
              <div class="flex flex-col items-center mt-4">
                <span class="text-sm text-gray-700 dark:text-gray-400">
                  Showing <span class="font-semibold text-lg text-gray-700 dark:text-white">{(currentPage - 1) * itemsPerPage + 1}</span> to <span class="font-semibold text-gray-900 dark:text-white">{Math.min(currentPage * itemsPerPage, totalEntries)}</span> of <span class="font-semibold text-gray-900 dark:text-white">{totalEntries}</span> Entries
                </span>
                {/* <!-- Buttons --> */}
                <div class="inline-flex mt-2 xs:mt-0">
                  <button onClick={() => goToPreviousPage(currentPage)}
                    disabled={currentPage == 1} class="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-s hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                    <svg class="w-3.5 h-3.5 me-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5H1m0 0 4 4M1 5l4-4" />
                    </svg>
                    Prev
                  </button>

                  <div className="flex justify-evenly items-center">
                    {Array.from({ length: Math.ceil(totalPages / itemsPerPage) }).map(
                      (_, index) => (
                        <span
                          key={index + 1}
                          onClick={() => paginate(index + 1)}
                          className={`bg-blue-800 hover:bg-blue-950 text-white rounded-full p-1 w-10 h-10 mx-1 text-center flex justify-center items-center cursor-pointer ${index + 1 === currentPage ? "bg-blue-950" : ""
                            }`}
                        >
                          {index + 1}
                        </span>
                      )
                    )}
                  </div>
                  <button onClick={() => goToNextPage(currentPage)}
                    disabled={currentPage === Math.ceil(totalPages / itemsPerPage)} class="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 border-0 border-s border-gray-700 rounded-e hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                    Next
                    <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                    </svg>
                  </button>
                </div>
              </div>
            }
          </div>
        </div>
      )}
    </>
  );
};

export default MemberTable;
