import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import SpinnerOverlay from '../Other/Loader';
import EmptyPage from '../Other/Empty';
import axios from 'axios';
import Swal from 'sweetalert2';

const LoanDetailsCard = () => {

    const text = "details for this loan";

    const { id } = useParams();

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loanDetails, setLoanDetails] = useState({});

    useEffect(() => {
        async function getloanDetails() {
            const cacheBuster = new Date().getTime();
            axios.get(`https://django.burundientempsreel.com/btr/cotisations/loans/${id}/details/?cacheBuster=${cacheBuster}`)
                .then(response => {
                    // console.log('Response:', response.data);
                    if (response.data.error) {
                        toast.error(response.data.error);
                    } else {
                        setLoanDetails(response.data);
                    }
                })
                .catch(error => {
                    console.log('Error:', error);
                }).finally(() => { setLoading(false); });
        }
        getloanDetails();
    }, [id])

    function isDateDueNotPassed(dateDue) {
        const currentDate = new Date();
        const dueDate = new Date(dateDue);

        return dueDate.getTime() > currentDate.getTime();
    }

    const calculateUpdatedAmount = (loanData) => {
        const { amount, interest_rate, date_taken } = loanData;
        const today = new Date();
        const takenDate = new Date(date_taken);
        const monthsPassed = (today.getFullYear() - takenDate.getFullYear()) * 12 + (today.getMonth() - takenDate.getMonth());

        let updatedAmount = parseFloat(amount);
        console.log("monthsPassed: ", monthsPassed);

        const interest = (interest_rate / 100) * monthsPassed * updatedAmount;
        updatedAmount = updatedAmount + parseFloat(interest);

        return updatedAmount;
    };

    const handleReturnLoan = (loan_id) => {
        console.log("Returned loan id: ", loan_id);
        Swal.fire({
            title: "Is loan returned?!",
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.patch(`https://django.burundientempsreel.com/loans/${loan_id}/status/`, {
                    "is_returned": true
                })
                    .then(response => {
                        // Handle successful deletion
                        // console.log("Del res: ", response.data);
                        toast.success('Loan returned.', { autoClose: 3000, position: "top-right" });
                        navigate('/view-all/loans/');
                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error deleting member:', error);
                        // Display an error message or perform any additional actions
                    });
            } else {
                console.log("Deletion canceled!");
            }
        });
    }

    const handleNotReturnedLoan = (loan_id) => {
        console.log("Not returned loan id: ", loan_id);
        Swal.fire({
            title: "Is loan not returned?!",
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.patch(`https://django.burundientempsreel.com/loans/${loan_id}/status/`, {
                    "is_returned": false
                })
                    .then(response => {
                        // Handle successful deletion
                        // console.log("Del res: ", response.data);
                        toast.success('Loan not returned activated.', { autoClose: 3000, position: "top-right" });
                        navigate('/view-all/loans/');
                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error deleting member:', error);
                        // Display an error message or perform any additional actions
                    });
            } else {
                console.log("Deletion canceled!");
            }
        });
    }

    const handleDeleteLoan = () => {
        Swal.fire({
            title: "Do you want to delete this loan? If you are not sure, you may deactivate it!",
            showCancelButton: true,
            confirmButtonText: "Delete",
            icon: "warning",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.delete(`https://django.burundientempsreel.com/btr/cotisations/loans/${id}/delete/`)
                    .then(response => {
                        // Handle successful deletion
                        // console.log("Del res: ", response.data);
                        toast.success('Loan deleted successfully', { autoClose: 3000, position: "top-right" });
                        navigate('/view-all/loans/');
                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error deleting loan:', error);
                        // Display an error message or perform any additional actions
                    });
            } else {
                console.log("Deletion canceled!");
            }
        });
    };

    return (
        <div className='max-w-screen-xl mx-auto p-4 m-3 bg-slate-100'>
            <SpinnerOverlay isLoading={loading} />

            {Object.keys(loanDetails).length > 0 ?
                <div className='flex flex-col'>

                    <div className='flex justify-start items-center mb-3'>
                        <Link to={`/view-all/loans/`}
                            className="px-4 py-2 bg-blue-500 text-white rounded-md shadow-md text-center"
                        >
                            Return
                        </Link>
                    </div>

                    <div className='flex justify-center items-center mb-3'>
                        <h2 className='font-bold text-2xl'>Loan details</h2>
                    </div>

                    <div className='flex justify-center items-center flex-wrap'>
                        <div className="px-4 py-3 w-fit m-2 rounded-md shadow-md bg-gray-200 flex flex-col justify-center items-center">
                            <div className="font-bold text-xl mb-2">Borrower Details</div>
                            <div className='flex flex-wrap justify-center items-center'>
                                <div className="w-28 h-28 flex justify-center items-center rounded-full border border-slate-500">
                                    <img src={`${loanDetails.borrower.profile_picture}`} className='w-full h-full rounded-full object-contain' />
                                </div>
                                <div className='m-1 p-1'>
                                    <p><span className="font-semibold">Name:</span> {loanDetails.borrower.Nom} {loanDetails.borrower.Prenom}</p>
                                    <p><span className="font-semibold">Email:</span> <a className='text-blue-600' href={`mailto:${loanDetails.borrower.email}`}>{loanDetails.borrower.email}</a></p>
                                    <p><span className="font-semibold">Phone Number:</span> <a className='text-blue-600' href={`tel:${loanDetails.borrower.phone_number}`}>{loanDetails.borrower.phone_number}</a></p>
                                    <p><span className="font-semibold">Address:</span> {loanDetails.borrower.address}</p>
                                </div>
                            </div>
                        </div>

                        {/* Group details */}
                        <div className="px-4 py-3 w-fit m-2  rounded-md shadow-md bg-gray-200 flex flex-col justify-center items-center">
                            <div className="font-bold text-xl mb-2">Group Details</div>
                            <div className='flex flex-wrap justify-center items-center'>
                                <div className="w-16 h-16 flex justify-center items-center rounded-full border border-slate-500">
                                    <img src={`${loanDetails.group.logo}`} className='w-full h-full rounded-full object-contain' />
                                </div>
                                <div className='m-1 p-1'>
                                    <p><span className="font-semibold">Name:</span> {loanDetails.group.name}</p>
                                    {/* <p><span className="font-semibold">Code:</span> {loanDetails.group.code}</p> */}
                                    <p><span className="font-semibold">Slogan:</span> {loanDetails.group.slogan}</p>
                                </div>
                            </div>
                        </div>

                        <div className="px-4 py-3 w-fit m-2 rounded-md shadow-md bg-gray-200 flex flex-col justify-center items-center">
                            <div className="font-bold text-xl mb-2">User details</div>
                            <div className='flex flex-wrap justify-center items-center'>
                                <div className="w-20 h-20 flex justify-center items-center rounded-full border border-slate-500">
                                    <img src={`${loanDetails.user.profile_picture}`} className='w-full h-full rounded-full object-contain' />
                                </div>
                                <div className='m-1 p-1'>
                                    <p><span className="font-semibold">Full name: <span className='font-normal'>{loanDetails.user.first_name + ' ' + loanDetails.user.last_name}</span></span></p>
                                    <p><span className="font-semibold">Username: <span className='font-normal'>{loanDetails.user.username}</span></span></p>
                                    <div><span className="font-semibold text-ellipsis text-wrap"><a className='text-wrap text-ellipsis text-blue-700' href={`mailto:${loanDetails.user.email}`}>{loanDetails.user.email}</a></span></div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col justify-center items-center px-4 py-3 w-fit m-2 rounded-md shadow-md bg-red-200 border-2 border-red-700 ">
                            <div className="font-bold text-xl mb-2">Loan Details</div>
                            <div className="flex flex-wrap justify-center items-center">
                                <div className="w-56 h-52 flex justify-center items-center rounded-full border border-slate-500 m-1">
                                    <img src={`${loanDetails.proof_image}`} className='w-full h-full rounded-full object-cover' />
                                </div>
                                <div className='m-1 p-1'>
                                    <p><span className="font-semibold">Amount: </span>{loanDetails.amount.toLocaleString()}</p>
                                    <p><span className="font-semibold">Date Taken:</span> {new Date(loanDetails.date_taken).toLocaleString('en-US', {
                                        year: 'numeric', month: 'long', day: 'numeric',
                                    })}</p>
                                    <p><span className="font-semibold">Date Due:</span> {new Date(loanDetails.date_due).toLocaleString('en-US', {
                                        year: 'numeric', month: 'long', day: 'numeric',
                                    })}</p>
                                    <p><span className="font-semibold">Interest Rate:</span> {loanDetails.interest_rate}</p>
                                    <p><span className="font-semibold">Description:</span> {loanDetails.description}</p>
                                    <p className={`p-1 text-center rounded-md text-white ${loanDetails.is_returned ? "bg-green-800" : "bg-red-800"}`}><span className="font-semibold">Loan returned:</span> {loanDetails.is_returned ? <span className=''>Yes</span> :
                                        <span className=''>No</span>}</p>
                                    <p className='text-center'><span className="font-semibold">Is due date passed:  </span>
                                        {!isDateDueNotPassed(loanDetails.date_due) ? <span className='text-white font-bold text-xl'>Yes</span> :
                                            <span className='text-green-900 font-bold text-xl'>No</span>
                                        }</p>

                                    <div className='flex justify-center items-center bg-white rounded-md p-2'>
                                        <p><span className="font-semibold text-red-900">Amount the member will return by now: </span>{calculateUpdatedAmount(loanDetails).toLocaleString()} FBu</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap justify-end mt-4">
                        <button
                            className="px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded-md shadow-md mr-2 text-center"
                            onClick={() => handleReturnLoan(loanDetails.id)}
                        >
                            Loan returned
                        </button>
                        <button
                            className="px-4 py-2 bg-orange-500 hover:bg-orange-700 text-white rounded-md shadow-md mr-2 text-center"
                            onClick={() => handleNotReturnedLoan(loanDetails.id)}
                        >
                            Loan not returned
                        </button>
                        <button
                            className="px-4 py-2 m-1 bg-red-500 hover:bg-red-700 text-white rounded-md shadow-md  text-center"
                            onClick={handleDeleteLoan}
                        >
                            Delete
                        </button>
                        <Link to={`/loans/${loanDetails.id}/edit`}
                            className="px-4 py-2 bg-blue-500 text-white rounded-md shadow-md text-center"
                        >
                            Edit Loan
                        </Link>
                    </div>
                </div>
                :
                <EmptyPage text={text} />
            }
        </div>
    );
};

export default LoanDetailsCard;
