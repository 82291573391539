import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";

const ContributionGroupForm = (props) => {

  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [codeSecurite, setCodeSecurite] = useState('');
  const [slogan, setSlogan] = useState('');
  const [logo, setLogo] = useState(null);

  const userId = localStorage.getItem('contrUserId');

  const [sending, setSending] = useState(false);

  const handleLogoChange = (event) => {
    setLogo(event.target.files[0]);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name: name,
      code: codeSecurite,
      slogan: slogan,
      logo: logo,
      user: parseInt(userId)
    };

    // console.log("FormData: ", formData);

    await axios.post('https://django.burundientempsreel.com/btr/cotisations/contribution_groups/create/',
      formData, { headers: { "Content-Type": "multipart/form-data" } }
    ).then((response) => {
      console.log(("Cont group : ", response.data));
      if (response.data.success) {
        toast.success('Contribution group created successfully!', { autoClose: 3000, position: "top-right" });
        // Clear form after successful submission (optional)
        setName('');
        setCodeSecurite('');
        setSlogan('');
        setLogo(null);

        props.setRegister(false);
        props.setLogin(false);
      }

      if (response.data.error) {
        if (response.data.message.non_field_errors) {
          toast.error(response.data.message.non_field_errors[0], {
            autoClose: 3000,
            position: "top-right",
          })
        }
        else if (response.data.message.name[0]) {
          toast.error(response.data.message.name[0], {
            autoClose: 3000,
            position: "top-right",
          })
        }
      }

    }).catch((err) => {
      console.log("Error: ", err);
    }).finally(() => { setSending(false); })
  };

  const handleRegister = () => {
    props.setRegister(false);
  }

  const handleLoginLink = () => {
    props.setRegister(false);
    props.setLogin(true);
  }

  return (
    <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-80">
      <div className='max-w-screen-sm w-full p-4 m-3 rounded-md shadow-md bg-gray-200'>
        <div className='flex justify-between items-center mb-3 mx-2'>
          <h2 className='font-bold text-2xl text-orange-500'>Create a New Contribution Group</h2>
          <button onClick={handleRegister} className='bg-blue-800 p-1 rounded-full w-10 h-10 cursor-pointer'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path className='text-red-700' d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" /></svg>
          </button>
        </div>

        <div className='border border-orange-700 rounded-md p-4'>
          <form onSubmit={handleSubmit} className='grid grid-cols-2 gap-4'>
            <div className='flex flex-col'>
              <label htmlFor='name' className='text-sm font-medium mb-2'>
                Name:
              </label>
              <input
                type='text'
                id='name'
                value={name}
                onChange={(e) => setName(e.target.value)}
                className='rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500'
              />
            </div>
            <div className='flex flex-col'>
              <label
                htmlFor='code_securite'
                className='text-sm font-medium mb-2'
              >
                Security Code:
              </label>
              <input
                type='password'
                id='code_securite'
                value={codeSecurite}
                onChange={(e) => setCodeSecurite(e.target.value)}
                className='rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500'
              />
            </div>
            <div className='flex flex-col col-span-2'>
              <label htmlFor='slogan' className='text-sm font-medium mb-2'>
                Slogan:
              </label>
              <textarea
                id='slogan'
                value={slogan}
                onChange={(e) => setSlogan(e.target.value)}
                className='rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500'
              />
            </div>
            <div className='flex flex-col col-span-2'>
              <label htmlFor='logo' className='cursor-pointer text-center text-sm font-medium mb-2 rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-orange-500 hover:bg-orange-700'>
                Select the association/group Logo
              </label>
              <input type="file" accept="image/*"
                id='logo'
                onChange={handleLogoChange}
                className='hidden'
              />
              {logo && <img src={URL.createObjectURL(logo)} className="border w-60 h-auto flex self-center border-gray-300 rounded-md" />}
            </div>

            <div className="flex justify-self-end items-center self-end col-span-2">
              <div className="col-span-full flex justify-self-end">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center justify-center"
                >
                  <span className="mr-2">Create Contribution Group</span>
                </button>
              </div>
            </div>
            <div className='flex justify-end items-center mb-3 col-span-2'>
              <p className='text-sm flex flex-row'>
                <span className="m-1">Have a Group?</span>
                <button onClick={handleLoginLink} className="text-blue-600 m-1 hover:text-blue-800">Login your contribution group</button>
              </p>
            </div>
          </form>
        </div>
        <SpinnerOverlay isLoading={sending} />
      </div>
    </div>
  );
};

export default ContributionGroupForm;


const SpinnerOverlay = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <div className="fixed top-0 left-0 z-50 w-full h-full flex flex-col items-center justify-center bg-slate-300 bg-opacity-10">
          <div>
            <div className="flex justify-center items-center space-x-2">
              <span className="text-blue-900 text-2xl font-serif animate-bounce">Creating contribution group</span>
              <div className="w-3 h-3 bg-blue-900 rounded-full animate-bounce"></div>
              <div className="w-3 h-3 bg-blue-900 rounded-full animate-bounce"></div>
              <div className="w-3 h-3 bg-blue-900 rounded-full animate-bounce"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
