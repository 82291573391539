import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import SpinnerOverlay from '../Other/Loader';
import EmptyPage from '../Other/Empty';
import axios from 'axios';
import Swal from 'sweetalert2';

const ContributionDetailsCard = () => {

    const text = "details for this contribution"

    const { id } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [contributionDetails, setContributionDetails] = useState({});

    useEffect(() => {
        async function getContributionDetails() {
            const cacheBuster = new Date().getTime();
            axios.get(`https://django.burundientempsreel.com/btr/cotisations/contributions/${id}/details/?cacheBuster=${cacheBuster}`)
                .then(response => {
                    // console.log('Response:', response.data);
                    if (response.data) {
                        setContributionDetails(response.data);
                    }
                })
                .catch(error => {
                    console.log('Error:', error);
                }).finally(() => { setLoading(false); });
        }
        getContributionDetails();
    }, [id])

    const handleActivateContribution = (contrId) => {
        console.log("Activating contribution id: ", contrId);
        Swal.fire({
            title: "Do you want to activate this contribution?",
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.patch(`https://django.burundientempsreel.com/btr/contributions/${contrId}/status/`, {
                    "is_activated": true
                })
                    .then(response => {
                        // console.log("Del res: ", response.data);
                        toast.success('Contribution activated successfully.', { autoClose: 3000, position: "top-right" });
                        navigate('/contributions/view/all/');
                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error deleting member:', error);
                        // Display an error message or perform any additional actions
                    });
            } else {
                console.log("Deletion canceled!");
            }
        });
    }

    const handleNotDeactivateContribution = (contrId) => {
        console.log("Deactivating contribution id: ", contrId);
        Swal.fire({
            title: "Do you want to deactivate this contribution?!",
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.patch(`https://django.burundientempsreel.com/btr/contributions/${contrId}/status/`, {
                    "is_activated": false
                })
                    .then(response => {
                        // console.log("Del res: ", response.data);
                        toast.success('Contribution deactivated successfully.', { autoClose: 3000, position: "top-right" });
                        navigate('/contributions/view/all/');
                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error deactivating contribution:', error);
                        // Display an error message or perform any additional actions
                    });
            } else {
                console.log("Deactivating canceled!");
            }
        });
    }

    return (
        <div className='max-w-screen-xl mx-auto p-4 m-3 bg-slate-100'>
            <SpinnerOverlay isLoading={loading} />

            {Object.keys(contributionDetails).length > 0 ?
                <div className='flex flex-col'>

                    <div className='flex justify-start items-center mb-3'>
                        <Link to={`/contributions/view/all/`}
                            className="px-4 py-2 bg-blue-500 text-white rounded-md shadow-md text-center"
                        >
                            Return
                        </Link>
                    </div>

                    <div className='flex justify-center items-center mb-3'>
                        <h2 className='font-bold text-2xl'>Contribution details</h2>
                    </div>

                    <div className='flex justify-center items-center flex-wrap'>
                        {/* Member details */}
                        <div className="px-6 w-fit py-4  m-3 rounded-md shadow-md bg-gray-200 flex flex-col justify-center items-center">
                            <div className="font-bold text-xl mb-2">Member Details</div>
                            <div className="w-40 h-40 flex justify-center items-center rounded-full border border-slate-500">
                                <img src={`${contributionDetails.member.profile_picture}`} className='w-full h-full rounded-full object-cover' />
                            </div>
                            <p><span className="font-semibold">Name:</span> {contributionDetails.member.Nom} {contributionDetails.member.Prenom}</p>
                            <p><span className="font-semibold">Email:</span> <a className='text-blue-600' href={`mailto:${contributionDetails.member.email}`}>{contributionDetails.member.email}</a></p>
                            <p><span className="font-semibold">Phone Number:</span> <a className='text-blue-600' href={`tel:${contributionDetails.member.phone_number}`}>{contributionDetails.member.phone_number}</a></p>
                            <p><span className="font-semibold">Address:</span> {contributionDetails.member.address}</p>
                        </div>

                        {/* Group details */}
                        <div className="px-6 py-4 w-fit m-3 rounded-md shadow-md bg-gray-200 flex flex-col justify-center items-center">
                            <div className="font-bold text-xl mb-2">Group Details</div>
                            <div className="w-40 h-auto flex justify-center items-center rounded-full border border-slate-500">
                                <img src={`${contributionDetails.group.logo}`} className='w-full h-full rounded-full object-contain' />
                            </div>
                            <p><span className="font-semibold">Name:</span> {contributionDetails.group.name}</p>
                            {/* <p><span className="font-semibold">Code:</span> {contributionDetails.group.code}</p> */}
                            <p><span className="font-semibold">Slogan:</span> {contributionDetails.group.slogan}</p>
                        </div>

                        {/* Contribution type details */}
                        <div className="px-6 py-4 w-fit m-3 rounded-md shadow-md bg-gray-200 flex flex-col justify-center items-center">
                            <div className="font-bold text-xl mb-2">Contribution Type Details</div>
                            <p><span className="font-semibold">Name:</span> {contributionDetails.contribution_type.name}</p>
                            <p><span className="font-semibold">Description:</span> {contributionDetails.contribution_type.description}</p>
                        </div>

                        {/* Contribution details */}
                        <div className="px-6 py-4 w-fit m-3 rounded-md shadow-md bg-red-200 border-2 border-red-700 flex flex-col justify-center items-center">
                            <div className="font-bold text-xl mb-2">Contribution Details</div>
                            <p><span className="font-semibold">Amount:</span> {contributionDetails.amount}</p>
                            <p><span className="font-semibold">Payment Date:</span> {contributionDetails.payment_date}</p>
                            <p><span className="font-semibold">Details:</span> {contributionDetails.details}</p>
                            <p className={`p-1 rounded-md text-white ${contributionDetails.is_activated ? "bg-green-800" : "bg-red-800"}`}>
                                <span className="font-semibold">Contribution activated:</span> {contributionDetails.is_activated ? <span className=''>Yes</span> :
                                    <span className=''>No</span>}</p>
                        </div>
                    </div>

                    <div className="flex flex-wrap justify-end mt-4">
                        <button
                            className="px-4 py-2 m-1 bg-green-500 hover:bg-green-700 text-white rounded-md shadow-md text-center"
                            onClick={() => handleActivateContribution(contributionDetails.id)}
                        >
                            Activate
                        </button>
                        <button
                            className="px-4 py-2 m-1 bg-red-500 hover:bg-red-700 text-white rounded-md shadow-md  text-center"
                            onClick={() => handleNotDeactivateContribution(contributionDetails.id)}
                        >
                            Deactivate
                        </button>
                        <Link unselectable='off' to={`/contribution/${contributionDetails.id}/edit`}
                            className="px-4 py-2 m-1 bg-blue-500 hover:bg-blue-700 text-white rounded-md shadow-md text-center"
                        >
                            Edit Contribution
                        </Link>
                    </div>
                </div>
                :
                <EmptyPage text={text} />
            }
        </div>
    );
};

export default ContributionDetailsCard;
