import React, { useEffect, useRef, useState } from 'react';
import SpinnerOverlay from '../Other/Loader';
import axios from 'axios';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { FaDatabase, FaPrint } from 'react-icons/fa';

const ReportTable = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    const groupId = localStorage.getItem("contGroupId");
    const userId = localStorage.getItem('contrUserId');

    // console.log("Group id:: ", groupId);

    useEffect(() => {
        const fetchExpenses = async () => {
            const cacheBuster = new Date().getTime();
            await axios.get(`https://django.burundientempsreel.com/cotisations/statistics/report/group/${groupId}/?cacheBuster=${cacheBuster}`)
                .then(response => {
                    if (response.data) {
                        setData(response.data);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                })
                .finally(() => {
                    setLoading(false);
                });
        };
        fetchExpenses();
    }, [groupId]);

    function getLabel(value) {
        const valueLabelPairs = [
            ['income', 'Income'],
            ['expense', 'Expense'],
            ['transfer', 'Transfer'],
        ];

        for (const pair of valueLabelPairs) {
            const [val, label] = pair;
            if (val === value) {
                return label;
            }
        }

        return 'Unknown';
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [screenSize, setScreenSize] = useState(window.innerWidth < 622);
    useEffect(() => {
        const screenSizeChange = () => {
            setScreenSize(window.innerWidth < 622);
        }
        window.addEventListener('resize', screenSizeChange);
        return () => {
            window.removeEventListener('resize', screenSizeChange);
        }
    }, [])

    return (
        <div className='max-w-screen-xl mx-auto p-4 m-3 rounded-md shadow-md bg-slate-100'>
            {loading ?
                <SpinnerOverlay isLoading={loading} /> :
                <>
                    {Object.keys(data).length > 0 ?
                        <>
                            <div className=" overflow-x-auto w-full overflow-y-hidden p-2">

                                <div ref={componentRef} className='p-2'>
                                    <h2 className="text-2xl font-bold mb-4 text-center">Statistics Report</h2>
                                    <p className="mb-4 text-center text-gray-700 text-lg">
                                        This report provides a summary of expenses, contributions, loans, and interests.
                                    </p>

                                    {data.expenses.length > 0 &&
                                        <>
                                            <div className='flex justify-center items-center mb-2'>
                                                <h2 className='font-bold text-sky-900 text-2xl'>All expenses</h2>
                                            </div>
                                            <div className={` ${screenSize ? "w-[50em]" : "w-full"} flex flex-col justify-center  mb-4 p-2 rounded-md shadow-md bg-white`}>

                                                <table className="w-full divide-y divide-gray-200">
                                                    <thead className='bg-green-300'>
                                                        <tr>
                                                            <th className="border text-lg border-gray-400 px-4 py-2 text-left font-medium tracking-wider">Title</th>
                                                            <th className="border text-lg border-gray-400 px-4 py-2 text-left font-medium tracking-wider">Amount</th>
                                                            <th className="border text-lg border-gray-400 px-4 py-2 font-medium text-center tracking-wider">Category</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-orange-200 divide-y divide-gray-200">
                                                        {data.expenses.map((expense) => (
                                                            <tr key={expense.id}>
                                                                <td className="border border-gray-400 px-4 py-2 whitespace-nowrap">{expense.title}</td>
                                                                <td className="border border-gray-400 px-4 py-2 whitespace-nowrap">{expense.amount.toLocaleString()}</td>
                                                                <td className={`border-2 px-4 py-2 text-white text-center ${getLabel(expense.category) === 'Income' ? 'bg-green-600' :
                                                                    getLabel(expense.category) === 'Expense' ? 'bg-red-600' : "bg-blue-600"}`}>
                                                                    {getLabel(expense.category)}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    }

                                    {data.loans.length > 0 &&
                                        <>
                                            <div className='flex justify-center items-center mb-2'>
                                                <h2 className='font-bold w-full text-red-900 text-2xl text-center'>All loans</h2>
                                            </div>

                                            <div className={` ${screenSize ? "w-[50em]" : "w-full"} flex flex-col justify-center  mb-4 p-2 rounded-md shadow-md bg-white`}>

                                                <table className="w-full divide-y divide-gray-200">
                                                    <thead className='bg-blue-300'>
                                                        <tr>
                                                            <th className="border text-lg border-gray-400 px-4 py-2 text-center font-medium tracking-wider">Full name</th>
                                                            <th className="border text-lg border-gray-400 px-4 py-2 text-center font-medium tracking-wider">Amount</th>
                                                            <th className="border text-lg border-gray-400 px-4 py-2 text-center font-medium tracking-wider">Date taken</th>
                                                            <th className="border text-lg border-gray-400 px-4 py-2 text-center font-medium tracking-wider">Returned</th>
                                                            <th className="border text-lg border-gray-400 px-4 py-2 text-center font-medium tracking-wider">Interest</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-slate-200 divide-y divide-gray-200">
                                                        {data.loans.map((loan) => (
                                                            <tr key={loan.id}>
                                                                <td className="border border-gray-400 px-4 py-2 whitespace-nowrap text-center">{loan.full_name}</td>
                                                                <td className="border border-gray-400 px-4 py-2 whitespace-nowrap text-center">{loan.amount.toLocaleString()}</td>
                                                                <td className="border border-gray-400 px-4 py-2 whitespace-nowrap text-center">{loan.date_taken}</td>
                                                                <td className={`border-2 px-4 py-2 text-white text-center ${loan.is_returned ? "bg-green-700" : "bg-red-700"}`}>{loan.is_returned ? "Returned" : "Not returned"}</td>
                                                                <td className="border border-gray-400 px-4 py-2 whitespace-nowrap text-center">{loan.interest.toLocaleString()}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    }

                                    <div className='w-full'>
                                        <div className='flex justify-center items-center mb-2'>
                                            <h2 className='font-bold text-red-400 text-2xl'>Summary</h2>
                                        </div>

                                        <div className={` ${screenSize ? "w-[50em]" : "w-full"} flex flex-col justify-center  mb-4 p-2 rounded-md shadow-md bg-white`}>

                                            <table className="table-auto w-full border-collapse divide-gray-200 rounded">
                                                <thead className='bg-cyan-900'>
                                                    <tr>
                                                        <th className="border border-gray-400 px-4 py-2 text-white">Category</th>
                                                        <th className="border border-gray-400 px-4 py-2 text-white">Total Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody className='bg-slate-700'>
                                                    <tr className='bg-blue-800'>
                                                        <td className="border border-gray-400 px-4 py-2 text-center text-white">Total Contributions</td>
                                                        <td className="border border-gray-400 px-4 py-2 text-center text-white">{data.total_contributions.toLocaleString()}</td>
                                                    </tr>
                                                    <tr className='bg-orange-800'>
                                                        <td className="border border-gray-400 px-4 py-2 text-center text-white">Total Expenses</td>
                                                        <td className="border border-gray-400 px-4 py-2 text-center text-white">{data.total_amount.total_expenses.toLocaleString()}</td>
                                                    </tr>
                                                    <tr className='bg-orange-800'>
                                                        <td className="border border-gray-400 px-4 py-2 text-center text-white">Total Transfers</td>
                                                        <td className="border border-gray-400 px-4 py-2 text-center text-white">{data.total_amount.total_transfers.toLocaleString()}</td>
                                                    </tr>
                                                    <tr className='bg-blue-800'>
                                                        <td className="border border-gray-400 px-4 py-2 text-center text-white">Total Income</td>
                                                        <td className="border border-gray-400 px-4 py-2 text-center text-white">{data.total_amount.total_income.toLocaleString()}</td>
                                                    </tr>
                                                    <tr className='bg-orange-800'>
                                                        <td className="border border-gray-400 px-4 py-2 text-center text-white">Total Loans</td>
                                                        <td className="border border-gray-400 px-4 py-2 text-center text-white">{data.total_amount.total_loans.toLocaleString()}</td>
                                                    </tr>
                                                    <tr className='bg-blue-800'>
                                                        <td className="border border-gray-400 px-4 py-2 text-center text-white">Total Interests</td>
                                                        <td className="border border-gray-400 px-4 py-2 text-center text-white">{data.total_interests.toLocaleString()}</td>
                                                    </tr>
                                                    <tr className='bg-green-800'>
                                                        <td className="border border-gray-400 px-4 py-2 text-center text-white">Total Amount without interest</td>
                                                        <td className={`border border-gray-400 px-4 py-2 text-center text-white ${data.total_amount.total_amount < 0 && "bg-purple-700"}`}>{data.total_amount.total_amount > 0 ?
                                                            <span>{data.total_amount.total_amount.toLocaleString()}</span> : <span className='text-white-600'>{data.total_amount.total_amount.toLocaleString()}</span>
                                                        }</td>
                                                    </tr>
                                                    <tr className='bg-green-800'>
                                                        <td className="border border-gray-400 px-4 py-2 text-center text-white">Total balance</td>
                                                        <td className={`border border-gray-400 px-4 py-2 text-center text-white ${data.balance <= 0 && "bg-red-700"}`}>
                                                            {data.balance > 0 ?
                                                                <span>{data.balance.toLocaleString()}</span> :
                                                                <span className='text-white-600'>{data.balance.toLocaleString()}</span>
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className='w-full flex flex-col justify-center items-center ml-10'>
                                        <div className='flex flex-col mb-2'>
                                            <h2 className='font-bold text-slate-800 text-xl text-center'>Fait , le {new Date().toLocaleDateString()}, par</h2>
                                            <h2 className='font-bold text-slate-700 text-lg text-center'>Le Directeur de Finance</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>












                            {/* Print button */}
                            <div className="flex justify-center">
                                <button onClick={handlePrint} className="bg-blue-500 flex flex-col justify-center items-center hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                                    <FaPrint />
                                    <span>Print Report</span>
                                </button>
                            </div>
                        </>
                        :
                        <div className="flex bg-red-200 p-2 rounded-md shadow-md flex-col justify-center items-center text-center text-gray-600">
                            <FaDatabase size={50} />
                            <p className='text-lg text-center'>No data available</p>
                        </div>
                    }
                </>
            }
        </div>
    );
};

export default ReportTable;


// function ReportToPrint({ data, componentRef }) {

//     function getLabel(value) {
//         const valueLabelPairs = [
//             ['income', 'Income'],
//             ['expense', 'Expense'],
//             ['transfer', 'Transfer'],
//         ];

//         for (const pair of valueLabelPairs) {
//             const [val, label] = pair;
//             if (val === value) {
//                 return label;
//             }
//         }

//         return 'Unknown';
//     }
//     return (
//         <div ref={componentRef} className="max-w-4xl mx-auto px-4 py-8">
//             <h2 className="text-2xl font-bold mb-4 text-center">Statistics Report</h2>
//             <p className="mb-4 text-center text-gray-700 text-lg">
//                 This report provides a summary of expenses, contributions, loans, and interests.
//             </p>

//             {data.expenses.length > 0 &&
//                 <div className='flex flex-col justify-center items-center mb-4 p-2 rounded-md shadow-md bg-white'>
//                     <div className='flex justify-center items-center mb-2'>
//                         <h2 className='font-bold text-sky-900 text-2xl'>All expenses</h2>
//                     </div>

//                     <table className="w-full divide-y divide-gray-200">
//                         <thead className='bg-green-300'>
//                             <tr>
//                                 <th className="border text-lg border-gray-400 px-4 py-2 text-left font-medium tracking-wider">Title</th>
//                                 <th className="border text-lg border-gray-400 px-4 py-2 text-left font-medium tracking-wider">Amount</th>
//                                 <th className="border text-lg border-gray-400 px-4 py-2 font-medium text-center tracking-wider">Category</th>
//                             </tr>
//                         </thead>
//                         <tbody className="bg-orange-200 divide-y divide-gray-200">
//                             {data.expenses.map((expense) => (
//                                 <tr key={expense.id}>
//                                     <td className="border border-gray-400 px-4 py-2 whitespace-nowrap">{expense.title}</td>
//                                     <td className="border border-gray-400 px-4 py-2 whitespace-nowrap">{expense.amount}</td>
//                                     <td className={`border-2 px-4 py-2 text-white text-center ${getLabel(expense.category) === 'Income' ? 'bg-green-600' :
//                                         getLabel(expense.category) === 'Expense' ? 'bg-red-600' : "bg-blue-600"}`}>
//                                         {getLabel(expense.category)}
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             }

//             {data.loans.length > 0 &&
//                 <div className='flex flex-col justify-center items-center mb-4 p-2 rounded-md shadow-md bg-red-200'>
//                     <div className='flex justify-center items-center mb-2'>
//                         <h2 className='font-bold text-red-900 text-2xl'>All loans</h2>
//                     </div>

//                     <table className="w-full divide-y divide-gray-200">
//                         <thead className='bg-blue-300'>
//                             <tr>
//                                 <th className="border text-lg border-gray-400 px-4 py-2 text-center font-medium tracking-wider">Full name</th>
//                                 <th className="border text-lg border-gray-400 px-4 py-2 text-center font-medium tracking-wider">Amount</th>
//                                 <th className="border text-lg border-gray-400 px-4 py-2 text-center font-medium tracking-wider">Date taken</th>
//                                 <th className="border text-lg border-gray-400 px-4 py-2 text-center font-medium tracking-wider">Returned</th>
//                                 <th className="border text-lg border-gray-400 px-4 py-2 text-center font-medium tracking-wider">Interest</th>
//                             </tr>
//                         </thead>
//                         <tbody className="bg-slate-200 divide-y divide-gray-200">
//                             {data.loans.map((loan) => (
//                                 <tr key={loan.id}>
//                                     <td className="border border-gray-400 px-4 py-2 whitespace-nowrap text-center">{loan.full_name}</td>
//                                     <td className="border border-gray-400 px-4 py-2 whitespace-nowrap text-center">{loan.amount}</td>
//                                     <td className="border border-gray-400 px-4 py-2 whitespace-nowrap text-center">{loan.date_taken}</td>
//                                     <td className={`border-2 px-4 py-2 text-white text-center ${loan.is_returned ? "bg-green-700" : "bg-red-700"}`}>{loan.is_returned ? "Returned" : "Not returned"}</td>
//                                     <td className="border border-gray-400 px-4 py-2 whitespace-nowrap text-center">{loan.interest}</td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             }

//             <div className='flex flex-col justify-center items-center mb-4 p-2 rounded-md shadow-md bg-green-900'>
//                 <div className='flex justify-center items-center mb-2'>
//                     <h2 className='font-bold text-red-400 text-2xl'>Summary</h2>
//                 </div>

//                 <table className="table-auto w-full border-collapse divide-gray-200 rounded">
//                     <thead className='bg-cyan-900'>
//                         <tr>
//                             <th className="border border-gray-400 px-4 py-2 text-white">Category</th>
//                             <th className="border border-gray-400 px-4 py-2 text-white">Total Amount</th>
//                         </tr>
//                     </thead>
//                     <tbody className='bg-slate-700'>
//                         <tr className='bg-blue-800'>
//                             <td className="border border-gray-400 px-4 py-2 text-center text-white">Total Contributions</td>
//                             <td className="border border-gray-400 px-4 py-2 text-center text-white">{data.total_contributions}</td>
//                         </tr>
//                         <tr className='bg-orange-800'>
//                             <td className="border border-gray-400 px-4 py-2 text-center text-white">Total Expenses</td>
//                             <td className="border border-gray-400 px-4 py-2 text-center text-white">{data.total_amount.total_expenses}</td>
//                         </tr>
//                         <tr className='bg-orange-800'>
//                             <td className="border border-gray-400 px-4 py-2 text-center text-white">Total Transfers</td>
//                             <td className="border border-gray-400 px-4 py-2 text-center text-white">{data.total_amount.total_transfers}</td>
//                         </tr>
//                         <tr className='bg-blue-800'>
//                             <td className="border border-gray-400 px-4 py-2 text-center text-white">Total Income</td>
//                             <td className="border border-gray-400 px-4 py-2 text-center text-white">{data.total_amount.total_income}</td>
//                         </tr>
//                         <tr className='bg-orange-800'>
//                             <td className="border border-gray-400 px-4 py-2 text-center text-white">Total Loans</td>
//                             <td className="border border-gray-400 px-4 py-2 text-center text-white">{data.total_amount.total_loans}</td>
//                         </tr>
//                         <tr className='bg-blue-800'>
//                             <td className="border border-gray-400 px-4 py-2 text-center text-white">Total Interests</td>
//                             <td className="border border-gray-400 px-4 py-2 text-center text-white">{data.total_interests}</td>
//                         </tr>
//                         <tr className='bg-green-800'>
//                             <td className="border border-gray-400 px-4 py-2 text-center text-white">Total Amount</td>
//                             <td className={`border border-gray-400 px-4 py-2 text-center text-white ${data.total_amount.total_amount < 0 && "bg-red-700"}`}>{data.total_amount.total_amount > 0 ?
//                                 <span>{data.total_amount.total_amount}</span> : <span className='text-white-600'>{data.total_amount.total_amount}</span>
//                             }</td>
//                         </tr>
//                     </tbody>
//                 </table>
//             </div>
//         </div>
//     )
// }