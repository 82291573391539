import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SpinnerOverlay from '../Other/Loader';
import Swal from 'sweetalert2';

const AccountManagement = (props) => {
    const [user, setUser] = useState({});
    const [userSummary, setUserSummary] = useState({});
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const userId = localStorage.getItem('contrUserId');
    const groupId = localStorage.getItem('contGroupId');


    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`https://django.burundientempsreel.com/btr/cotisations/users/${userId}/`);
                // console.log("Res data: ", response.data);

                setUser(response.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchUserData();
    }, [userId]);

    useEffect(() => {
        const fetchUserSummaryData = async () => {
            try {
                const response = await axios.get(`https://django.burundientempsreel.com/btr/cotisations/get-user-summary/${userId}/`);
                // console.log("Res summary data: ", response.data);
                setUserSummary(response.data);
            } catch (error) {
                console.error('Error fetching user summary:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchUserSummaryData();
    }, [userId]);

    const handleLogout = () => {
        Swal.fire({
            title: "Do you really want to sign out?",
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                localStorage.removeItem("contrToken");
                localStorage.removeItem("contrUserId");
                props.setIsLoading1(true);
                navigate('/');

                console.log("Logout successfull");
            } else {
                console.log("Logout canceled!");
            }
        });
    };

    const handleDeleteAccount = () => {
        // Implement your delete account logic here
        console.log("Edit Account clicked");
    };

    return (
        <div className='max-w-screen-xl mx-auto p-2 m-3'>
            <SpinnerOverlay isLoading={loading} />
            {!loading &&
                <>
                    <div className="w-full mx-auto">
                        <h1 className="text-2xl text-center font-bold mb-4">Account Management</h1>
                        <div className="border border-gray-300 p-2 rounded-md shadow-md">
                            <div className="flex flex-wrap justify-center items-center w-full">
                                <div className='w-48 h-48 flex justify-center border border-gray-400 items-center rounded-full m-2'>
                                    <img src={`${user.profile_picture}`} alt={user.Nom} className='w-full h-full rounded-full object-cover' />
                                </div>
                                <div className="flex flex-col justify-start items-start border rounded-md border-gray-400 bg-slate-200 shadow-md p-1 m-1">
                                    <p className='text-sm'>
                                        <strong>Username:</strong> <span className='font-serif'>{user.username}</span>
                                    </p>
                                    <p className='text-sm text-wrap'>
                                        <strong>Email:</strong> <a className='hover:text-blue-900 text-blue-700 break-all' href={`mailto:${user.email}`}>{user.email}</a>
                                    </p>

                                    {user.phone_number &&
                                        <p className='text-sm text-wrap'>
                                            <strong>Phone number:</strong> <a className='hover:text-blue-900 text-blue-700 break-all' href={`tel:${user.phone_number}`}>{user.phone_number}</a>
                                        </p>
                                    }
                                    <p className='text-sm'>
                                        <strong>First Name:</strong> <span className='font-serif'>{user.first_name}</span>
                                    </p>
                                    <p className='text-sm'>
                                        <strong>Last Name:</strong> <span className='font-serif'>{user.last_name}</span>
                                    </p>
                                </div>
                            </div>

                            <div className="mt-4 justify-center items-center">
                                <h2 className="text-lg font-semibold text-center mb-2">Your actions summarization</h2>
                            </div>

                            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 justify-center'>
                                <div className="flex flex-col justify-center p-2 m-1 bg-gray-200 border-2 border-green-600 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700">
                                    <div className='mb-1 flex self-center'>
                                        <span className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">{userSummary.contribution_group_added_count}</span>
                                    </div>
                                    <p className="mb-1 font-normal text-center text-gray-700 dark:text-gray-400">All Contribution Group that you have added till now.</p>
                                </div>

                                <div className="flex flex-col justify-center p-2 m-1 bg-gray-200 border-2 border-cyan-600 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700">
                                    <div className='mb-1 flex self-center'>
                                        <span className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">{userSummary.member_added_count}</span>
                                    </div>
                                    <p className="mb-1 font-normal text-center text-gray-700 dark:text-gray-400">All members that you have added till now.</p>
                                </div>

                                <div className="flex flex-col justify-center p-2 m-1 bg-slate-200 border-2 border-orange-600 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700">
                                    <div className='mb-1 flex self-center'>
                                        <span className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">{userSummary.loan_given_total.toLocaleString()} FBu</span>
                                    </div>
                                    <p className="mb-1 font-normal text-center text-gray-700 dark:text-gray-400">Total amount of loans that you gave.</p>
                                </div>

                                <div className="flex flex-col justify-center p-2 m-1 bg-slate-200 border-2 border-red-600 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700">
                                    <div className='mb-1 flex self-center'>
                                        <span className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">{userSummary.expenses_made_total.toLocaleString()} FBu</span>
                                    </div>
                                    <p className="mb-1 font-normal text-center text-gray-700 dark:text-gray-400">Total amount of expenses used.</p>
                                </div>

                                <div className="flex flex-col justify-center p-2 m-1 bg-slate-200 border-2 border-yellow-500 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700">
                                    <div className='mb-1 flex self-center'>
                                        <span className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">{userSummary.total_transfers.toLocaleString()} FBu</span>
                                    </div>
                                    <p className="mb-1 font-normal text-center text-gray-700 dark:text-gray-400">Total amount of transfers made.</p>
                                </div>

                                <div className="flex flex-col justify-center p-2 m-1 bg-slate-200 border-2 border-emerald-800 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700">
                                    <div className='mb-1 flex self-center'>
                                        <span className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">{userSummary.total_income.toLocaleString()} FBu</span>
                                    </div>
                                    <p className="mb-1 font-normal text-center text-gray-700 dark:text-gray-400">Total income amount</p>
                                </div>
                            </div>

                            <div className="flex justify-end mt-4">
                                <button
                                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mr-2 rounded"
                                    onClick={handleLogout}
                                >
                                    Logout
                                </button>
                                <button disabled
                                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                                    onClick={handleDeleteAccount}
                                >
                                    Edit info
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

export default AccountManagement;