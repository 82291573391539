import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from "axios";
// import { ClipLoader } from "react-spinners";

import logo from '../../favicon.png'
import SpinnerOverlay from '../Other/Loader';

const LoginForm = ({ setIsLoading1, isLoading1 }) => {

    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();
        // Implement login functionality here

        const formData = {
            username: username, password: password
        };

        // console.log("Login credentials: ", formData);

        setIsLoading(true);

        await axios.post('https://django.burundientempsreel.com/btr/user/login/', formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(response => {
            // console.log("Login response data: ", response.data);

            if (response.data.success) {
                toast.success("Connected successfully", {
                    autoClose: 3000,
                    position: "top-right",
                });

                localStorage.setItem("contrToken", response.data.token);
                localStorage.setItem("contrUserId", response.data.userId);

                console.log("Token and userId saved to local storage");

                setIsLoading1(false);
            }
        }).catch(err => {
            console.log("Error logging in: ", err);
        }).finally(() => { setIsLoading(false); });
    };

    return (
        <div className="max-w-md mx-auto p-3">
            <div className="bg-slate-100 shadow-md rounded flex flex-col justify-center p-2">
                <div className="block self-center max-w-60 max-h-60 mb-4 border border-gray-400 rounded-md">
                    <img src={`${logo}`} alt='Logo' className='rounded-md w-full h-full object-cover ' />
                </div>
                <form onSubmit={handleLogin} className="px-2 pt-1.5 pb-2 mb-2">
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                            Username
                        </label>
                        <input
                            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="username"
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            Password
                        </label>
                        <input
                            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="password"
                            type="password"
                            placeholder="******************"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="flex items-center justify-end">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="submit"
                        >
                            Sign In
                        </button>
                    </div>
                    <div className='flex justify-end items-center mb-3'>
                        <p className='text-sm flex flex-row'>
                            <span className="m-1">No account yet?</span>
                            <Link to="/admin/register/" className="text-blue-600 m-1 hover:text-blue-800">Signup here</Link>
                        </p>
                    </div>
                </form>
            </div>
            <SpinnerOverlay isLoading={isLoading} />
        </div>
    );
};

export default LoginForm;
