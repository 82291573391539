const SpinnerOverlay = ({ isLoading }) => {
    return (
        <>
            {isLoading && (
                <div className="fixed top-0 left-0 z-50 w-full h-full flex flex-col items-center justify-center bg-slate-500 bg-opacity-30">
                    <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-blue-800"></div>
                    <div>
                        <div className="flex justify-center items-center space-x-2">
                            <span className="text-blue-600 text-xl font-serif animate-pulse">Please wait</span>
                            <div className="w-2 h-2 bg-blue-600 rounded-full animate-bounce"></div>
                            <div className="w-2 h-2 bg-blue-600 rounded-full animate-bounce"></div>
                            <div className="w-2 h-2 bg-blue-600 rounded-full animate-bounce"></div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SpinnerOverlay;

