import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, redirect } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Import the css styles combined with Tailwindcss
import "./App.css";

// HomePage
import HomePage from "./Components/Home/HomePage";

// For users
import UserForm from "./Components/Users/AdminUserCreate";
import LoginForm from "./Components/Users/Login";

// For members
import MemberForm from "./Components/Members/AddMember";
import MemberTable from "./Components/Members/AllMembers";
import MemberDetails from "./Components/Members/MemberDetails";

// For contributions
import ContributionForm from "./Components/Cotisations/ContributionForm";
import ContributionTable from "./Components/Cotisations/ContributionTable";
import ContributionDetailsCard from "./Components/Cotisations/ContributionDetails";

// For contributions group
import LoginContributionGroupForm from "./Components/ContributionGroup/LoginContrGroup";
import ContributionGroupCreateForm from "./Components/ContributionGroup/CreateContrGroup";
import ContributionGroupTable from "./Components/ContributionGroup/ContributionGroupTable";

// For Contribution Types
import ContributionTypeForm from "./Components/Contribution-Types/ContributionType";
import ContributionTypeTable from "./Components/Contribution-Types/ContributionTypeTable";

// For expenses
import CreateExpense from "./Components/Depenses/AddExpense";
import ExpenseTable from "./Components/Depenses/AllExpenses";

// For NavBar and Errors
import NavBar from "./Components/Other/NavBar";
import ErrorPage from "./Components/Other/ErrorPage";
import LoanTable from "./Components/Loans/AllLoans";
import LoanDetailsCard from "./Components/Loans/LoanDetails";
import ExpenseDetailsCard from "./Components/Depenses/ExpenseDetails";
import ReportTable from "./Components/Stats/StatsReport";
import AccountManagement from "./Components/Users/MyAccount";
import axios from "axios";
import Footer from "./Components/Other/FooterBar";

function App() {

  const [isFullLoading, setIsFullLoading] = useState(true);
  const [isContrGroup, setIsContrGroup] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  // localStorage.removeItem("contrToken");
  // localStorage.removeItem("contGroupId");
  // https://django.burundientempsreel.com/btr/cotisations/contribution_groups/2/

  const token = localStorage.getItem("contrToken");
  const contGroupId = localStorage.getItem("contGroupId");

  useEffect(() => {
    async function LoginUserWithToken() {
      const formData = {
        token: token,
      };

      try {
        const response = await fetch(
          "https://django.burundientempsreel.com/btr/user/login/with-token/",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData),
          }
        );

        const data = await response.json();
        if (data.success) {
          setIsLoading(false);
          redirect("/");
        } else if (data.error) {
          console.log('Login error:', data);
          setIsLoading(true);
          redirect("/");
        }
        // Handle successful login (e.g., store token, redirect to dashboard)
      } catch (error) {
        console.log("Catch Error: ", error);
      } finally {
        setIsFullLoading(false);
      }
    }
    LoginUserWithToken();
  }, [token]);

  useEffect(() => {
    async function getGroupData() {
      const cacheBuster = new Date().getTime();
      axios.get(`https://django.burundientempsreel.com/btr/cotisations/contribution_groups/${contGroupId}/?${cacheBuster}`)
        .then(response => {
          // console.log("Check response data: ", response.data);
          if (response.data.id) {
            setIsContrGroup(true);
          } else {
            setIsContrGroup(false);
          }
        })
        .catch(error => {
          setIsContrGroup(false);
          console.error(error);
        });
    }
    getGroupData();
  }, [contGroupId])

  return (
    <>
      {isFullLoading ? (
        <div className="flex flex-col min-h-screen justify-center items-center">
          <ClipLoader size={50} color="gray" />
          <h1>Loading...</h1>
        </div>
      ) : (
        <BrowserRouter>
          {isLoading ? (
            <div className="">
              <Routes>

                <Route path="/" element={<LoginForm setIsLoading1={setIsLoading}
                  isLoading1={isLoading} />} />
                <Route path="admin/register/" element={<UserForm />} />

              </Routes>
              <ToastContainer />
            </div>
          ) : (
            <div className="flex flex-col min-h-screen">

              {isContrGroup ?
                <div className="flex-grow">
                  <NavBar setIsLoading1={setIsLoading} />
                  <Routes>
                    <Route path="/" element={<HomePage />} />

                    <Route path="members/create/" element={<MemberForm />} />
                    <Route path="members/view/all/" element={<MemberTable />} />
                    <Route path="member/details/:memberId/" element={<MemberDetails />} />


                    <Route path="contributions/create/:memberId" element={<ContributionForm />} />
                    <Route path="contributions/view/all/" element={<ContributionTable />} />
                    <Route path="contributions/details/:id/" element={<ContributionDetailsCard />} />


                    <Route path="contribution-type/create/" element={<ContributionTypeForm />} />
                    <Route path="contribution-type/view/all/" element={<ContributionTypeTable />} />


                    <Route path="contribution-group/view/all/" element={<ContributionGroupTable />} />
                    <Route path="contribution-group/connect/" element={<LoginContributionGroupForm />} />
                    <Route path="contribution-group/create/" element={<ContributionGroupCreateForm />} />


                    <Route path="expense/create/" element={<CreateExpense />} />
                    <Route path="expenses/view/all/" element={<ExpenseTable />} />
                    <Route path="expense/details/:id/" element={<ExpenseDetailsCard />} />


                    <Route path="/view-all/loans/" element={<LoanTable />} />
                    <Route path="loan/details/:id/" element={<LoanDetailsCard />} />


                    <Route path="statistics/report/" element={<ReportTable />} />
                    <Route path="view/my-account" element={<AccountManagement setIsLoading1={setIsLoading} />} />


                    <Route path="*" element={<ErrorPage />} />
                  </Routes>
                </div> :
                <div className="flex-grow">
                  <Routes>
                    <Route path="/" element={<LoginContributionGroupForm />} />
                    <Route path="contribution-group/create/" element={<ContributionGroupCreateForm />} />

                  </Routes>
                </div>
              }
              <Footer />

              <ToastContainer />
            </div>
          )}
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
