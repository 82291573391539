import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { useNavigate } from 'react-router-dom';
import SpinnerOverlay from '../Other/Spinner';

const CreateExpense = () => {

  const navigate = useNavigate();

  const groupId = localStorage.getItem("contGroupId");
  const userId = localStorage.getItem('contrUserId');

  const [title, setTitle] = useState('');
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [proofImage, setProofImage] = useState(null);

  const [selectedOption, setSelectedOption] = useState(null);

  const categoryOptions = [
    { value: 'income', label: 'Income' },
    { value: 'expense', label: 'Expense' },
    { value: 'transfer', label: 'Transfer' },
  ];

  const [sending, setSending] = useState(false);

  const handleCategoryChange = (selectedOption) => {
    setCategory(selectedOption);
    setSelectedOption(selectedOption.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (title.trim() === "") {
      toast.warning("The title of the expense is required!");
      return;
    }
    if (amount.trim() === "") {
      toast.warning("The amount of the expense is required!");
      return;
    }
    if (date.trim() === "") {
      toast.warning("The date of the expense is required!");
      return;
    }
    if (description.trim() === "") {
      toast.warning("The description of the expense is required!");
      return;
    }
    if (!selectedOption) {
      toast.warning("The category of the expense is required!");
      return;
    }
    if (!groupId) {
      toast.warning("Return to home to log in a contribution group!");
      return;
    }
    if (!userId) {
      toast.warning("You are not connected! Please log in to add an expense!");
      return;
    }

    let expenseData = {
      "title": title,
      "amount": parseFloat(amount),
      "date": date,
      "description": description,
      "category": selectedOption,
      "group": parseInt(groupId),
      "user": parseInt(userId),
    };

    if (proofImage) {
      expenseData = {
        ...expenseData,
        "proof_image": proofImage,
      }
    }

    // console.log("Expense data: ", expenseData);

    setSending(true);

    axios.post('https://django.burundientempsreel.com/btr/cotisations/expense/create/', expenseData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
      .then(response => {
        if (response.data.success) {
          toast.success(`Expense added successfully`);
          navigate("/expenses/view/all/");
        } else {
          toast.info("An error occurred while creating the expense. Please try again!", {
            autoClose: 3000, position: "top-right"
          })
        }
      }).
      catch((error) => {
        console.error('Error creating expense:', error);
        alert('An error occurred while creating the expense. Please try again.');
      }).finally(() => {
        setSending(false);
      });
  };

  const handlePictureChange = (e) => {
    setProofImage(e.target.files[0]);
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className='max-w-screen-sm w-full p-4 m-3 rounded-md shadow-md bg-gray-200'>
        <h1 className="text-2xl text-orange-600 font-bold mb-4 text-center">Add Expense Information</h1>
        <form onSubmit={handleSubmit} className="">
          <div className="mb-2">
            <label htmlFor="title" className="block font-semibold">Title:</label>
            <input type="text" id="title" name="title" value={title} onChange={(e) => setTitle(e.target.value)} className="border rounded-md px-3 py-2 w-full" />
          </div>

          <div className="mb-2">
            <label htmlFor="amount" className="block font-semibold">Amount:</label>
            <input type="text" id="amount" name="amount" value={amount} onChange={(e) => setAmount(e.target.value)} className="border rounded-md px-3 py-2 w-full" />
          </div>

          <div className="mb-2">
            <label htmlFor="date" className="block font-semibold">Date:</label>
            <input type="date" id="date" name="date" value={date} onChange={(e) => setDate(e.target.value)} className="border rounded-md px-3 py-2 w-full" />
          </div>

          <div className="mb-2">
            <label htmlFor="category" className="block font-semibold">Category:</label>
            <Select
              id="category"
              name="category"
              value={category}
              onChange={handleCategoryChange}
              options={categoryOptions}
              className="w-full py-1"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="description" className="block font-semibold">Description:</label>
            <textarea id="description" name="description" value={description} onChange={(e) => setDescription(e.target.value)} className="border rounded-md px-3 py-2 w-full" />
          </div>

          <div className="flex flex-col mb-2">
            <h1 className="text-sm font-medium mb-2">
              Proof image (optional):
            </h1>
            <label htmlFor="profile_picture" className="cursor-pointer text-center text-sm font-medium mb-2 rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-orange-500 hover:bg-orange-700">
              Attach the proof image
            </label>
            <input
              type="file" accept="image/*"
              id="profile_picture"
              onChange={handlePictureChange}
              className="hidden"
            />
            {proofImage && <img src={URL.createObjectURL(proofImage)} className="w-36 h-36 border border-gray-300 rounded-md" />}
          </div>

          <div className="flex justify-end items-center">
            <div className="col-span-full flex justify-self-end">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center justify-center"
              >
                <span className="mr-2">Add Expense</span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <SpinnerOverlay isLoading={sending} />
    </div>
  );
};

export default CreateExpense;
