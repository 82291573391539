import React, { useEffect, useId, useState } from 'react';
import { Link } from 'react-router-dom';

import ContributionGroupLoginForm from '../ContributionGroup/GroupLogin';
import ContributionGroupForm from '../ContributionGroup/ContributionGroupForm';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';

const HomePage = () => {

  const [login, setLogin] = useState(false);
  const [register, setRegister] = useState(false);
  const [groupData, setGroupData] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  const [loading, setLoading] = useState(true);

  const userId = localStorage.getItem('contrUserId');
  const groupId = localStorage.getItem('contGroupId');

  // console.log("User id: ", userId, " -- Group id: ", groupId);

  useEffect(() => {
    async function getContributionGroup() {
      const uniqueParam = new Date().toLocaleTimeString();
      await axios.get(`https://django.burundientempsreel.com/btr/cotisations/get-contribution-group/user/${userId}?cacheBuster=${uniqueParam}`)
        .then(response => {
          // console.log("Check response data: ", response.data);
          if (response.data.success) {
            setGroupData(response.data.data);
          } else if (response.data.notFound || response.data.error) {
            setGroupData({});
            setRegister(true);
            console.log(response.data.message);
          } else {
            setRegister(true);
          }
        }).catch(error => {
          console.log("Error: ", error);
        }).finally(() => { setLoading(false); });
    }
    getContributionGroup();
  }, [login, register]);

  // Function to handle group selection
  const handleGroupSelection = (groupId) => {
    // console.log("Selectd groupe: ", groupId);
    setSelectedGroupId(groupId);
    localStorage.setItem("contGroupId", groupId);
  };

  const [countData, setCountData] = useState([]);
  const [memberCount, setMemberCount] = useState(0);
  const [contributionTypeCount, setContributionTypeCount] = useState(0);
  const [contributionCount, setContributionCount] = useState(0);
  const [expensesCount, setExpensesCount] = useState(0);
  const [loanCount, setLoanCount] = useState(0);

  const cacheBuster = new Date().getTime();

  const urls = [
    `https://django.burundientempsreel.com/api/member/group/${groupId}/count/?cacheBuster=${cacheBuster}`,
    `https://django.burundientempsreel.com/api/contributiontype/group/${groupId}/count/?cacheBuster=${cacheBuster}`,
    `https://django.burundientempsreel.com/api/contribution/group/${groupId}/count/?cacheBuster=${cacheBuster}`,
    `https://django.burundientempsreel.com/api/expenses/group/${groupId}/count/?cacheBuster=${cacheBuster}`,
    `https://django.burundientempsreel.com/api/loan/group/${groupId}/count/?cacheBuster=${cacheBuster}`
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responses = await Promise.all(urls.map(url => axios.get(url)));
        const data = responses.map(response => response.data);
        // console.log("Counts data: ", data);

        setMemberCount(data[0].member_count);
        setContributionTypeCount(data[1].contribution_type_count);
        setContributionCount(data[2].contribution_count);
        setExpensesCount(data[3].expenses_count);
        setLoanCount(data[4].loan_count);

      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [])

  return (
    <>
      {loading ?
        <div className="flex flex-col min-h-screen justify-center items-center">
          <ClipLoader size={50} color="gray" />
          <div>
            <div className="flex justify-center items-center space-x-2">
              <span className="text-gray-700 text-2xl font-serif animate-bounce">Loading</span>
              <div className="w-3 h-3 bg-gray-700 rounded-full animate-bounce"></div>
              <div className="w-3 h-3 bg-gray-700 rounded-full animate-bounce"></div>
              <div className="w-3 h-3 bg-gray-700 rounded-full animate-bounce"></div>
            </div>
          </div>
        </div>
        :
        groupData.length > 0 ?
          <div className="max-w-screen-xl flex flex-col self-center mx-auto p-4 m-3">
            <h1 className="text-2xl font-bold mb-4 text-center"><span className=' text-animation'>Welcome to </span><span className=' text-animation-2'>Contribution and Expense Managements System</span></h1>

            <div className="mb-6 rounded-md flex flex-col justify-center self-center w-fit shadow-md bg-gray-200 p-2">
              <h1 className="text-lg mh-2 text-center uppercase font-serif text-blue-950">
                All your contribution groups
              </h1>

              <div className="my-2 flex justify-evenly items-center flex-wrap shadow-md bg-slate-300 p-2">
                {groupData.map((group, index) => (
                  <div key={index} className={`flex items-center justify-center mr-2 cursor-pointer rounded p-1 ${(selectedGroupId == group.id || groupId == group.id) && "border-blue-700 border"}`}
                    onClick={() => handleGroupSelection(group.id)}>
                    <img className="rounded-full w-20 h-20 border border-slate-500" src={`${group.logo}`} />
                    <div className="space-y-0.5 font-medium dark:text-white text-left rtl:text-right ms-3">
                      <div className='text-xl'>{group.name}</div>
                      <div className="text-sm text-gray-500 dark:text-gray-400 ">{group.slogan}</div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex items-center justify-center flex-wrap ml-2">
                <button onClick={() => { setLogin(!login) }} className='text-white m-1 bg-green-600 p-2 px-4 rounded hover:bg-green-800'>Specify your group</button>
                <button onClick={() => { setRegister(!register) }} className='text-white m-1 bg-blue-600 p-2 px-4 rounded hover:bg-blue-800'>Create another group</button>
              </div>
            </div>

            <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 grid-cols-1 gap-4">
              <div className="mb-6 rounded-md shadow-md bg-gray-200 p-2">
                <h2 className="flex justify-start items-center text-xl font-semibold mb-2"><div className='w-9 h-9 flex justify-center items-center bg-green-800 text-white rounded-full'>{memberCount}</div>Members</h2>
                <p>
                  Manage members of your organization. Add, edit, or delete member information such as name, email, phone number, address, and profile picture.
                </p>
                <div className="my-2">
                  <Link to="/members/view/all/" className="text-white bg-blue-600 p-2 px-4 rounded hover:bg-blue-800">View more</Link>
                </div>
              </div>

              <div className="mb-6 rounded-md shadow-md bg-gray-200 p-2">
                <h2 className="flex justify-start items-center text-xl font-semibold mb-2"><div className='w-9 h-9 flex justify-center items-center bg-green-800 text-white rounded-full'>{contributionTypeCount}</div>Contribution Types</h2>
                <p>
                  Define various contribution types such as monthly, quarterly, etc., along with their descriptions.
                </p>
                <div className="my-2">
                  <Link to="/contribution-type/view/all/" className="text-white bg-blue-600 p-2 px-4 rounded hover:bg-blue-800">View more</Link>
                </div>
              </div>

              <div className="mb-6 rounded-md shadow-md bg-gray-200 p-2">
                <h2 className="flex justify-start items-center text-xl font-semibold mb-2"><div className='w-9 h-9 flex justify-center items-center bg-green-800 text-white rounded-full'>{contributionCount}</div>Contributions</h2>
                <p>
                  Record contributions made by members. Specify the member, contribution type, amount, payment date, and any additional details.
                </p>
                <div className="my-2">
                  <Link to="/contributions/view/all/" className="text-white bg-blue-600 p-2 px-4 rounded hover:bg-blue-800">View more</Link>
                </div>
              </div>

              <div className="mb-6 rounded-md shadow-md bg-gray-200 p-2">
                <h2 className="flex justify-start items-center text-xl font-semibold mb-2"><div className='w-9 h-9 flex justify-center items-center bg-green-800 text-white rounded-full'>{expensesCount}</div>Expenses</h2>
                <p>
                  Log expenses incurred by the organization. Include details such as title, amount, date, description, category, and the group associated with the expense.
                </p>
                <div className="my-2">
                  <Link to="/expenses/view/all/" className="text-white bg-blue-600 p-2 px-4 rounded hover:bg-blue-800">View more</Link>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="mb-6 rounded-md shadow-md bg-gray-200 p-2">
            <h2 className="text-xl font-semibold mb-2">Contribution Group</h2>
            <p>
              Before adding users or performing any actions, please create a contribution group. To create a new contribution group, click on the button below 'Create Group' button. Once the group is created, you can insert users and start managing contributions.
            </p>
            <div className="my-2">
              <div className="flex items-center justify-center flex-wrap ml-2">
                <button onClick={() => { setLogin(!login) }} className='text-white m-1 bg-green-600 p-2 px-4 rounded hover:bg-green-800'>Specify your group</button>
                <button onClick={() => { setRegister(!register) }} className='text-white m-1 bg-blue-600 p-2 px-4 rounded hover:bg-blue-800'>Create another group</button>
              </div>
            </div>
          </div>

      }

      {register && <ContributionGroupForm setRegister={setRegister} setLogin={setLogin} />}
      {login && <ContributionGroupLoginForm setLogin={setLogin} setRegister={setRegister} />}

    </>
  );
};

export default HomePage;
